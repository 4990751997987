import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/shared/services/user.service';
import { User } from 'src/app/shared/models/user.model';
import { checkCountriesResponse } from 'src/app/shared/models/api-model';

interface Country {
  selected: boolean;
  country: string;
  country_alpha2: string;
  upload: string[];
  submitted: string[];
  forbidden: string[];
  tooltip: string;
}

@Component({
  selector: 'pwo-select-countries-dialog',
  templateUrl: './select-countries-dialog.component.html',
  styleUrls: ['./select-countries-dialog.component.scss']
})
export class SelectCountriesDialogComponent {

  count = 0;
  countries: Country[];

  constructor(
    public dialogRef: MatDialogRef<SelectCountriesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: checkCountriesResponse,
    private userService: UserService
  ) {

    this.userService.user.subscribe((user: User) => {
     // console.log(this.data);
      const aresByCountry = user.settings.assignedAREs;
      this.countries = this.data.countries.map(el => {
        // console.log('el', el);
        const ares = aresByCountry[el.country] || [];

        const forbidden = el.areToUpload.filter(item => !ares.includes(item));
        const upload = el.areToUpload.filter(item => ares.includes(item));
        const submitted = el.areSubmitted;

        let tooltip = '';
        if (upload.length) {
          tooltip += `Items with ARE ${ upload.join(', ') } will be uploaded.`;
        }
        if (submitted.length) {
          tooltip +=
          `You cannot upload for ARE ${ submitted.join(', ') } because ${submitted.length > 1 ? 'they were' : 'it was'} already submitted.`;
        }
        if (forbidden.length) {
          tooltip += `You don't have permission to upload for ARE ${ forbidden.join(', ') }.`;
        }

        return {
          country: el.country,
          country_alpha2: el.country_alpha2,
          selected: upload.length > 0,
          upload, submitted, forbidden, tooltip
        };
      });
      this.update();
    });
  }

  check(el, ev) {
    el.selected = ev.checked;
    this.update();
  }

  update() {
    this.count = 0;
    this.countries.forEach(el => {
      if (el.selected) { this.count++; }
    });
  }

  getSelected(): { countries: string[], AREs: string[] } {
    const sel = this.countries.filter(c => c.selected);
    const arr = sel.map(c => c.upload);
    return { countries: sel.map(el => el.country), AREs: [].concat(...arr) };
  }

  close(res) {
    this.dialogRef.close(res);
  }
}
