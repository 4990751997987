import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoringTableComponent } from './components/monitoring-table/monitoring-table.component';
import { SharedModule } from '../shared/shared.module';
// import { MonitoringRoutingModule } from './monitoring-routing.module';
import { PwoFilterCloudModule } from 'projects/pwo-filter-cloud/src/public_api';
import { UploadComponent } from './components/upload/upload.component';

@NgModule({
    declarations: [
        MonitoringTableComponent,
        UploadComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        // MonitoringRoutingModule,
        PwoFilterCloudModule
    ]
})
export class MonitoringModule { }
