import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pwo-accountant-decline-dialog',
  template: `
<h4 mat-dialog-title>Decline changes?</h4>
<div mat-dialog-content class="newton-form">
  <p>Do you want to decline the changes of the local SCM for {{ data.ARE }} in {{ data.month }}?</p>
  <p>After this, the local SCM can change the data for {{ data.country }} and has to submit again.</p>
  <mat-form-field style="margin-top: 10px;">
    <textarea matInput [(ngModel)]="comment" placeholder="Comment for local SCM" rows="5"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions class="button-container align-right" style="margin-top: 10px;">
  <button mat-button class="button" (click)="close(true)">Cancel</button>
  <button mat-button class="button" (click)="close()" cdkFocusInitial>Decline</button>
</div>
`
})
export class AccountantDeclineDialogComponent {

  comment = '';

  constructor(public dialogRef: MatDialogRef<AccountantDeclineDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  close(cancelled = false) {
    this.dialogRef.close({ cancelled, comment: this.comment });
  }
}
