import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/shared/services/export.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProgressDialogComponent } from 'src/app/shared/components/progress.component';
import { e } from 'src/app/shared/error-to-string';
import { ValueFilter } from 'pwo-filter-cloud';
import { UploadValidationTableComponent } from '../upload-validation-table/upload-validation-table.component';
import { BdmeDialogComponent } from '../bdme-dialog/bdme-dialog.component';
import { UserService } from 'src/app/shared/services/user.service';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'pwo-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  ARE = '';
  country = '';
  db = '';
  currentUser: User;

  @ViewChild('picker', { static: false }) datePicker: MatDatepicker<any>;

  constructor(
    public api: ApiService,
    private exportService: ExportService,
    private notif: NotificationService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit() {
    // this.month = this.api.formatMonth();
    // console.log(this.month);
    this.userService.user.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  clearARE() {
    this.api.clearARE(this.api.month.toISOString(), this.ARE).then(() => this.notif.info('Cleared submitted data of ARE'));
  }

  onMonthSelected(event) {
    this.api.setMonth(event);
    this.datePicker.close();
  }

  async export(type: 'data' | 'changes') {
    const progress = this.dialog.open(ProgressDialogComponent);
    const f = new ValueFilter('month');
    f.value = this.api.month.toISOString();

    try {
      let res: string;
      if (type === 'data') {
        res = await this.exportService.exportProd([ f.toFilterRecord() ]);
      } else {
        res = await this.exportService.exportChanges([ f.toFilterRecord() ]);
      }
      this.notif.info(res);

    } catch (err) {
      this.notif.error(e(err));
    }

    progress.close();
  }

  dump() {
    this.api.dump(this.db, this.country).then(data => {
      this.exportService.exportDataset(data);
    });
  }

  openBDME() {
    this.dialog.open(BdmeDialogComponent);
  }

  uploadValidationTable() {
    this.dialog.open(UploadValidationTableComponent);
  }
}
