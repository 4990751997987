import { Component, OnInit, Input } from '@angular/core';
import { PwoFilterCloudService } from 'pwo-filter-cloud';
import { PwoFilterService } from 'pwo-filter';

@Component({
  selector: 'pwo-validation-filter',
  templateUrl: './validation-filter.component.html',
  styleUrls: ['./validation-filter.component.scss']
})
export class ValidationFilterComponent implements OnInit {

  @Input() filter: any; // ValueFilter<number>;
  @Input() filterService: PwoFilterCloudService;

  // Hacky workaround to work with both PwoFilter and PwoFilterCloud
  fs: { applyFilter(filter: any): void };

  visible = false;

  constructor(private nonCloudFilterService: PwoFilterService) {
  }

  ngOnInit() {
    this.fs = this.filterService || this.nonCloudFilterService;
  }

  apply(val) {
    if (val === undefined) {
      this.filter.value = undefined;
    } else {
      this.filter.value = val ? 1 : 0;
    }
    this.fs.applyFilter(this.filter);
  }

}
