import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, pattern = 'mediumDate'): any {
    if (!value) {
      return value;
    }
    return moment(value)
      .locale(this.translateService.currentLang)
      .utc()
      .format('L');
  }

  getlocalizedIso(value: any) {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(value - tzoffset)).toISOString().slice(0, -1);
    return localISOTime;
  }

 parseDate(input) {
   if (this.translateService.currentLang === 'de') {
    const parts = input.match(/(\d+)/g);
    return new Date(parts[2], parts[1] - 1, parts[0]);
   }
  return new Date(input);
}

}
