
export class User {
    gid: string;
    firstName: string;
    lastName: string;
    mail: string;
    settings: UserSettings;
}

export type FleetRole = 'accountant' | 'scm' | 'none';
export interface AssignedARE {
    [key: string]: string[];
}

export interface UserSettings {
    role: FleetRole;
    monitoring: boolean;
    isLocalAdmin: boolean;
    isSystemAdmin: boolean;
    assignedAREs: AssignedARE;
    assignedAREsMonitoring: AssignedARE;
}

export type IFRS16Scope = 'fleet' | 'monitoring';

/**
 * Extracts all ARE the specified user is assigned to as a flat array.
 */
export function getAREList(user: User, scope: IFRS16Scope) {
    const AREs = scope === 'monitoring' ? user.settings.assignedAREsMonitoring : user.settings.assignedAREs;
    return [].concat(...Object.values(AREs || {}));
}

export function getDefaultUserSettings(): UserSettings {
    return {
        isSystemAdmin: false,
        isLocalAdmin: false,
        role: 'none',
        monitoring: false,
        assignedAREs: {},
        assignedAREsMonitoring: {}
    };
}
