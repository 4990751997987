import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pwo-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Input() value: string;
  @Output() valueChange = new EventEmitter();
  @Input() help: string[];

  constructor() { }

  ngOnInit() {
  }

  emit(val: string) {
    this.valueChange.next(val);
  }

}
