import { NgModule } from '@angular/core';
import { PwoFilterComponent } from './pwo-filter/pwo-filter.component';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DateFilterComponent } from './pwo-filter/filter-types/date-filter/date-filter.component';
import { MultiSelectFilterComponent } from './pwo-filter/filter-types/multi-select-filter/multi-select-filter.component';
import { SingleSelectFilterComponent } from './pwo-filter/filter-types/single-select-filter/single-select-filter.component';
import { RangeFilterComponent } from './pwo-filter/filter-types/range-filter/range-filter.component';
import { PwoFilterAnchorDirective } from './pwo-filter-anchor.directive';
import { SearchFilterComponent } from './pwo-filter/filter-types/search-filter/search-filter.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTableModule,
    MatChipsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDatepickerModule,
    MatTooltipModule
  ],
  exports: [
    PwoFilterComponent,
    PwoFilterAnchorDirective
  ],
  declarations: [
    PwoFilterComponent,
    DateFilterComponent,
    MultiSelectFilterComponent,
    SingleSelectFilterComponent,
    RangeFilterComponent,
    PwoFilterAnchorDirective,
    SearchFilterComponent
  ]
})
export class PwoFilterCloudModule {}
