import { Component, OnInit } from '@angular/core';
import { NotificationService, DEFAULT_DURATION } from '../../services/notification.service';

@Component({
  selector: 'pwo-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  notifications = [];

  constructor(private notif: NotificationService) { }

  ngOnInit() {
    this.notif.notification.subscribe(el => this.show(el));
  }

  show(notification) {
    this.notifications.splice(0, 0, notification);

    if (!notification.options.persistent) {
      setTimeout(() => {
        this.close(notification);
      }, notification.options.duration || DEFAULT_DURATION);
    }
  }

  close(el) {
    el.leave = true;
    setTimeout(() => {
      const index = this.notifications.indexOf(el);
      if (index >= 0) {
        this.notifications.splice(index, 1);
      }
    }, 300);
  }

  getAnimationDuration(el) {
    const duration = el.options.duration || DEFAULT_DURATION;
    return duration / 1000 + 's';
  }

}
