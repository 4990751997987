import { Component, OnInit, ViewChild } from '@angular/core';
import { MonitoringService, MonitoringContentType } from '../../services/monitoring.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UploadStatus } from '../../services/api-model';
import { e } from 'src/app/shared/error-to-string';
import { pauseFor } from '../../../shared/pause-for';

@Component({
  selector: 'pwo-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  isLoading = true;

  isUploading = false;
  uploadingInterval: number;
  statusText: string;

  itemCountPVO: number;
  itemCountOVO: number;

  isAggregating = false;

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  constructor(private monitoringService: MonitoringService, private notif: NotificationService,
    public dialogRef: MatDialogRef<UploadComponent>) { }

  ngOnInit() {
    this.loadStatus();
  }

  async loadStatus() {
    this.isLoading = true;
    const status = await this.monitoringService.getStatus();
    if (status) {
      this.applyStatus(status);
    } else {
      this.isUploading = false;
      this.itemCountPVO = undefined;
      this.itemCountOVO = undefined;
      this.stepper.selectedIndex = 0;
    }

    this.isLoading = false;

    if (this.isUploading) {
      this.pollStatus();
    }
  }

  onSelected(files: FileList, type: 'PVO' | 'OVO') {
    if (files.length > 0) {
      const file = files[0];

      // if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      console.log('File Type: ', file.type);
      console.log('Expected: ', MonitoringContentType);
      if (file.type === MonitoringContentType) {
        this.upload(file, type);
      } else {
        this.notif.error('File has not the correct type (' + file.type + ')');
      }
    } else {
      this.notif.error('Got no file');
    }
  }

  async upload(file: File, type: 'PVO' | 'OVO') {
    this.statusText = 'Uploading file...';
    this.isUploading = true;

    try {
      await this.monitoringService.upload(file, type);
      this.statusText = 'Parsing file...';

      const result = await this.pollStatus();
      console.log('Result: ', result);

    } catch (err) {
      this.notif.error('Error during file upload: ' + e(err));
    }
  }

  async pollStatus() {
    while (true) {
      const status = await this.monitoringService.getStatus();
      console.log('Status: ', JSON.stringify(status));
      if (status && status.isUploading === false) {
        this.applyStatus(status);
        break;
      }

      await pauseFor(30000);
    }
  }

  async clearMonth() {
    const confirmed = confirm('Are you sure you want to reset the current month? This will delete all entries including the comments.');

    if (confirmed) {
      this.isLoading = true;
      try {
        const res = await this.monitoringService.clearMonth();
        this.notif.info(res);
      } catch (err) {
        console.log(err);
        this.notif.error('Error clearing month: ' + e(err));
      }
      this.loadStatus();
    }
  }

  private applyStatus(status: UploadStatus) {
    this.isUploading = status.isUploading;
    this.itemCountPVO = status.itemCountPVO;
    this.itemCountOVO = status.itemCountOVO;
    while (this.stepper.selectedIndex < status.index) {
      this.stepper.next();
    }
  }

  getLabel(type) {
    const title = type === 'PVO' ? 'Ad Hoc Analysis (PVO)' : 'Order Volume Analysis (OVO)';
    const count = type === 'PVO' ? this.itemCountPVO : this.itemCountOVO;
    if (count === undefined) {
      return `Upload ${title}`;
    } else {
      return `${title}: ${count} items`;
    }
  }

  async aggregate() {
    this.isAggregating = true;
    try {
      const res = await this.monitoringService.aggregate();
      console.log(res);

      this.stepper.next();

    } catch (err) {
      console.log(err);
      this.notif.error('Error during aggregation: ' + e(err));
    }
    this.isAggregating = false;
  }

  close() {
    this.dialogRef.close();
  }

}
