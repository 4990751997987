const countries: Country[] = [
    { title: 'Aegypten', code: 'EGY', code2: 'EG' },
    { title: 'Algerien', code: 'DZA', code2: 'DZ' },
    { title: 'Angola', code: 'AGO', code2: 'AO' },
    { title: 'Argentinien', code: 'ARG', code2: 'AR' },
    { title: 'Armenien', code: 'ARM', code2: 'AM' },
    { title: 'Aserbaidschan', code: 'AZE', code2: 'AZ' },
    { title: 'Australien', code: 'AUS', code2: 'AU' },
    { title: 'Bahrain', code: 'BHR', code2: 'BH' },
    { title: 'Bangladesch', code: 'BGD', code2: 'BD' },
    { title: 'Belarus', code: 'BLR', code2: 'BY' },
    { title: 'Belgien', code: 'BEL', code2: 'BE' },
    { title: 'Bolivien, Plurinationaler Staat', code: 'BOL', code2: 'BO' },
    { title: 'Bosnien und Herzegowina', code: 'BIH', code2: 'BA' },
    { title: 'Brasilien', code: 'BRA', code2: 'BR' },
    { title: 'Brunei Darussalam', code: 'BRN', code2: 'BN' },
    { title: 'Bulgarien', code: 'BGR', code2: 'BG' },
    { title: 'Chile', code: 'CHL', code2: 'CL' },
    { title: 'China', code: 'CHN', code2: 'CN' },
    { title: 'Costa Rica', code: 'CRI', code2: 'CR' },
    { title: 'Cote d\'Ivoire', code: 'CIV', code2: 'CI' },
    { title: 'Daenemark', code: 'DNK', code2: 'DK' },
    { title: 'Djibouti', code: 'DJI', code2: 'DJ' },
    { title: 'Deutschland', code: 'DEU', code2: 'DE' },
    { title: 'Dominikanische Republik', code: 'DOM', code2: 'DO' },
    { title: 'Ecuador', code: 'ECU', code2: 'EC' },
    { title: 'El Salvador', code: 'SLV', code2: 'SV' },
    { title: 'Finnland', code: 'FIN', code2: 'FI' },
    { title: 'Frankreich', code: 'FRA', code2: 'FR' },
    { title: 'Ghana', code: 'GHA', code2: 'GH' },
    { title: 'Griechenland', code: 'GRC', code2: 'GR' },
    { title: 'Guatemala', code: 'GTM', code2: 'GT' },
    { title: 'Honduras', code: 'HND', code2: 'HN' },
    { title: 'Hongkong', code: 'HKG', code2: 'HK' },
    { title: 'Indien', code: 'IND', code2: 'IN' },
    { title: 'Indonesien', code: 'IDN', code2: 'ID' },
    { title: 'Irak', code: 'IRQ', code2: 'IQ' },
    { title: 'Iran, Islamische Republik', code: 'IRN', code2: 'IR' },
    { title: 'Irland', code: 'IRL', code2: 'IE' },
    { title: 'Israel', code: 'ISR', code2: 'IL' },
    { title: 'Italien', code: 'ITA', code2: 'IT' },
    { title: 'Jamaika', code: 'JAM', code2: 'JM' },
    { title: 'Japan', code: 'JPN', code2: 'JP' },
    { title: 'Jordanien', code: 'JOR', code2: 'JO' },
    { title: 'Jungferninseln, Britische', code: 'VGB', code2: 'VG' },
    { title: 'Kaimaninseln', code: 'CYM', code2: 'KY' },
    { title: 'Kanada', code: 'CAN', code2: 'CA' },
    { title: 'Kasachstan', code: 'KAZ', code2: 'KZ' },
    { title: 'Katar', code: 'QAT', code2: 'QA' },
    { title: 'Kenia', code: 'KEN', code2: 'KE' },
    { title: 'Kolumbien', code: 'COL', code2: 'CO' },
    { title: 'Korea, Republik', code: 'KOR', code2: 'KR' },
    { title: 'Kroatien', code: 'HRV', code2: 'HR' },
    { title: 'Kuwait', code: 'KWT', code2: 'KW' },
    { title: 'Lesotho', code: 'LSO', code2: 'LS' },
    { title: 'Luxemburg', code: 'LUX', code2: 'LU' },
    { title: 'Malaysia', code: 'MYS', code2: 'MY' },
    { title: 'Malta', code: 'MLT', code2: 'MT' },
    { title: 'Marokko', code: 'MAR', code2: 'MA' },
    { title: 'Mauretanien', code: 'MRT', code2: 'MR' },
    { title: 'Mauritius', code: 'MUS', code2: 'MU' },
    { title: 'Mexiko', code: 'MEX', code2: 'MX' },
    { title: 'Montenegro', code: 'MNE', code2: 'ME' },
    { title: 'Mosambik', code: 'MOZ', code2: 'MZ' },
    { title: 'Neukaledonien', code: 'NCL', code2: 'NC' },
    { title: 'Neuseeland', code: 'NZL', code2: 'NZ' },
    { title: 'Nicaragua', code: 'NIC', code2: 'NI' },
    { title: 'Niederlande', code: 'NLD', code2: 'NL' },
    { title: 'Nigeria', code: 'NGA', code2: 'NG' },
    { title: 'Norwegen', code: 'NOR', code2: 'NO' },
    { title: 'Oesterreich', code: 'AUT', code2: 'AT' },
    { title: 'Oman', code: 'OMN', code2: 'OM' },
    { title: 'Pakistan', code: 'PAK', code2: 'PK' },
    { title: 'Panama', code: 'PAN', code2: 'PA' },
    { title: 'Peru', code: 'PER', code2: 'PE' },
    { title: 'Philippinen', code: 'PHL', code2: 'PH' },
    { title: 'Polen', code: 'POL', code2: 'PL' },
    { title: 'Portugal', code: 'PRT', code2: 'PT' },
    { title: 'Rumaenien', code: 'ROU', code2: 'RO' },
    { title: 'Russische Foederation', code: 'RUS', code2: 'RU' },
    { title: 'Saudi-Arabien', code: 'SAU', code2: 'SA' },
    { title: 'Schweden', code: 'SWE', code2: 'SE' },
    { title: 'Schweiz', code: 'CHE', code2: 'CH' },
    { title: 'Serbien', code: 'SRB', code2: 'RS' },
    { title: 'Singapur', code: 'SGP', code2: 'SG' },
    { title: 'Slowakei', code: 'SVK', code2: 'SK' },
    { title: 'Slowenien', code: 'SVN', code2: 'SI' },
    { title: 'Spanien', code: 'ESP', code2: 'ES' },
    { title: 'Sri Lanka', code: 'LKA', code2: 'LK' },
    { title: 'Suedafrika', code: 'ZAF', code2: 'ZA' },
    { title: 'Taiwan', code: 'TWN', code2: 'TW' },
    { title: 'Tansania, Vereinigte Republik', code: 'TZA', code2: 'TZ' },
    { title: 'Thailand', code: 'THA', code2: 'TH' },
    { title: 'Trinidad und Tobago', code: 'TTO', code2: 'TT' },
    { title: 'Tschechien', code: 'CZE', code2: 'CZ' },
    { title: 'Tuerkei', code: 'TUR', code2: 'TR' },
    { title: 'Tunesien', code: 'TUN', code2: 'TN' },
    { title: 'Turkmenistan', code: 'TKM', code2: 'TM' },
    { title: 'Ukraine', code: 'UKR', code2: 'UA' },
    { title: 'Ungarn', code: 'HUN', code2: 'HU' },
    { title: 'Uruguay', code: 'URY', code2: 'UY' },
    { title: 'Usbekistan', code: 'UZB', code2: 'UZ' },
    { title: 'Venezuela, Bolivarische Republik', code: 'VEN', code2: 'VE' },
    { title: 'Vereinigte Arabische Emirate', code: 'ARE', code2: 'AE' },
    { title: 'Vereinigte Staaten', code: 'USA', code2: 'US' },
    { title: 'Vereinigtes Koenigreich', code: 'GBR', code2: 'GB' },
    { title: 'Vietnam', code: 'VNM', code2: 'VN' },
    { title: 'Zypern', code: 'CYP', code2: 'CY' }
];
export { countries };

export interface Country {
    title: string;
    code: string;
    code2: string;
}
