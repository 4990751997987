import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
//import { AuthGmsAngularClientModule } from './auth/auth.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { FooterComponent } from './static/footer/footer.component';
import { MenuComponent } from './static/menu/menu.component';
import { HeaderComponent } from './static/header/header.component';
import { LanguageMenuComponent } from './static/menu/menu-subcomponents/language-menu/language-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
//import { TokenInjector } from './auth/services/token.injector';
import { WebWorkerService } from 'ngx-web-worker';
import { ProgressDialogComponent } from './shared/components/progress.component';
import { IEDialogComponent } from './shared/components/ie-dialog.component';
import { UserSettingsComponent } from './static/menu/menu-subcomponents/user-settings/user-settings.component';
import { AdminSettingsComponent } from './static/menu/menu-subcomponents/admin-settings/admin-settings.component';
import { AdminModule } from './admin/admin.module';
import { FleetModule } from './fleet/fleet.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MonitoringModule } from './monitoring/monitoring.module';
//import { AWSHttpModule } from 'ngx-api-gateway-client/src';
import { AWSHttpModule} from '../ngx-api-gateway-client/aws-http.module';
import { AuthService } from './auth/services/auth.service';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthModule } from 'angular-auth-oidc-client';
import { AuthenticationModule } from './auth/auth.module';
import { TokenInjector } from './auth/services/token.injector';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.txt');
}

export function awsHttpConfigFactory() {
  return {
    region: 'eu-central-1',
    baseUrl: environment.webapi
  };
}

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        MenuComponent,
        HeaderComponent,
        LanguageMenuComponent,
        UserSettingsComponent,
        AdminSettingsComponent,
        ProgressDialogComponent,
        IEDialogComponent,
        LandingPageComponent
    ],
    imports: [
        //AuthGmsAngularClientModule.forRoot(environment.authConfig),
        SharedModule,
        AuthenticationModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AWSHttpModule.forRoot(awsHttpConfigFactory),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AdminModule,
        FleetModule,
        MonitoringModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true },
        {
          provide: APP_INITIALIZER,
          useFactory: initializeAppFactory,
          deps: [AuthService],
          multi: true,
        },
        TranslateModule,
        TranslateService,
        WebWorkerService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }

function initializeAppFactory(
  authService: AuthService
): () => Observable<boolean> {
  return () =>
    authService.isAuthenticated$.pipe(
      filter((auth) => !auth),
      tap(() => authService.login())
    );
}
