import { Injectable, Output, EventEmitter, ElementRef } from '@angular/core';

export interface MenuParams {
  title: string;
  component: any;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private _isOpened = false;
  get isOpened() {
    return this._isOpened;
  }
  set isOpened(val) {
    this._isOpened = val;

    const cl = document.querySelector('html').classList;
    if (val) { cl.add('noscroll'); } else { cl.remove('noscroll'); }
  }

  @Output() change: EventEmitter<MenuParams> = new EventEmitter();

  open(params: MenuParams) {
    this.isOpened = true;
    this.change.emit(params);
  }

  close() {
    this.isOpened = false;
  }
}
