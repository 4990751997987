import { Component, OnInit, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { AreSelectItem } from '../are-select/are-select.component';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { e } from 'src/app/shared/error-to-string';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSettings, User, IFRS16Scope, AssignedARE, getAREList } from 'src/app/shared/models/user.model';

export interface AreDialogData {
  scope: IFRS16Scope;
  assignedAREs: UserSettings['assignedAREs'];
  editor: User;
  purpose: 'assign' | 'bdme';
}

@Component({
  selector: 'pwo-are-dialog',
  templateUrl: './are-dialog.component.html',
  styleUrls: ['./are-dialog.component.scss']
})
export class AreDialogComponent implements OnInit {

  selection = new SelectionModel<AreSelectItem>(true, []);
  areList: AreSelectItem[];

  editor: User;
  assignedAREs: AssignedARE;
  scope: IFRS16Scope;
  purpose: 'assign' | 'bdme';

  editARE = false;
  isLoading = true;

  constructor(private api: ApiService, private notif: NotificationService,
    public dialogRef: MatDialogRef<AreDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: AreDialogData) { }

  async ngOnInit() {
    try {
      this.assignedAREs = this.data.assignedAREs;
      this.editor = this.data.editor;
      this.scope = this.data.scope;
      this.purpose = this.data.purpose;

      // Get ARE list for scope
      const areList = await this.api.getAREList();
      if (this.editor.settings.isSystemAdmin) {
        this.areList = areList;
      } else {
        const editorARE = getAREList(this.editor, this.scope);
        this.areList = areList.filter(el => editorARE.includes(el.ARE));
      }

      this.buildSelection();
      this.isLoading = false;

    } catch (err) {
      this.notif.error('Cannot get ARE list: ' + e(err));
    }
  }

  toggle() {
    if (this.editARE) {
      this.assignedAREs = this.buildAREList();
    }
    this.editARE = !this.editARE;
  }


  buildSelection() {
    this.selection.clear();

    const userARE = [].concat(...Object.values(this.assignedAREs));
    const filtered = this.areList.filter(el => userARE.includes(el.ARE));
    
    this.selection.select(...filtered);
  }

  buildAREList() {
    const areList: AreSelectItem[] = this.selection.selected;

    if (!this.editor.settings.isSystemAdmin) {
      // Add user ARE that editor is not allowed to edit (weren't even displayed in the table)
      const ares = this.areList.map(el => el.ARE);
      Object.keys(this.assignedAREs).forEach(country => {
        const userARE = this.assignedAREs[country].filter(el => !ares.includes(el));
        if (userARE.length > 0) {
          areList.push(...userARE.map(ARE => ({ ARE, country })));
        }
      });
    }

    const out = {};
    areList.forEach(item => {
      const c = item.country;
      if (out[c]) {
        out[c].push(item.ARE);
      } else {
        out[c] = [item.ARE];
      }
    });
    return out;
  }

  assign() {
    this.dialogRef.close(this.buildAREList());
  }

}
