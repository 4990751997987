

export interface MonitoringItem {
    id: number;
    po_key: string;
    po_number: string;
    month: string; // e.g. '2020-01'
    ARE: string;
    ifa_number: string;
    ifa_name: string;
    cost_center: string;
    country: string;
    business_unit: string;
    division: string;
    gl_accountant: string;
    profit_center: string;
    location: string;
    esn_source: string;
    esn_corporate: string;
    esn_corporate_idesc: string;
    aggregated: boolean;
    value_OVO: number;
    value_PVO: number;

    // Computed:
    accountability: string;
    category: 'A' | 'B' | 'C' | 'D';

    // Only included in PVO:
    business_segment: string;
    plant: string;
    buyer_invoice: string;
    buyer_purchase_org: string;
    invoice: string; // (User defined 3)
    invoice_number: string; // TODO check
    description: string;
}

export interface MonitoringRow extends MonitoringItem, Partial<MonitoringFeedback> {
    aggregated: boolean;
    timestamp: Date;
}
export interface MonitoringFeedback {
    feedback_timestamp: Date;
    feedback_user: string;
    standard_category: StandardCategory;
    comment: string;
    scm_star_reference: string;
}

export enum StandardCategory {
    SG0 = 'Lease (with lease term > 1 year)',
    SG1 = 'Lease (with lease term > 1 year but completely variable / usage based leases with no fixed payment amounts)',
    SG2 = 'Short-term lease (with lease term < 1 year)',
    SG3 = 'No lease (comment necessary - e.g. type of service, purchased asset, expense etc.)',
    SG4 = 'PO related to (dedicated) SCM Fleet managed contracts ',
    SG5 = 'Covered by central Real estate unit and reported via PLANON, e.g. RE, SHS RE, MO REM',
    SG6 = 'Already delivered as part of the Contract Collection of previous periods',
    SG7 = 'Other (comment necessary)'
}



/* FRONTEND CONFIG */

export interface MonitoringScheme {
    key: keyof MonitoringRow;
    name: string;
    selected: boolean;
    type?: string;
    filter?: string;
    important?: boolean;
    editable?: boolean;
}

export const SCHEMA: MonitoringScheme[] = [
    { key: 'id', name: 'ID', selected: false },
    { key: 'po_key', name: 'Key', selected: false },

    { key: 'esn_corporate', name: 'ESN Corporate', selected: true, important: true },
    { key: 'esn_corporate_idesc', name: 'ESN Corporate Idesc', selected: true },
    { key: 'division', name: 'Division', selected: true },
    { key: 'business_unit', name: 'Business Unit', selected: true },
    { key: 'business_segment', name: 'Business Segment', selected: true },
    { key: 'accountability', name: 'Accountability', selected: true, important: true },
    { key: 'ARE', name: 'ARE', selected: true, important: true },
    { key: 'country', name: 'Country', selected: true },
    { key: 'po_number', name: 'PO Number', selected: true, important: true },
    { key: 'category', name: 'Category', selected: true },
    { key: 'plant', name: 'Plant', selected: true },
    { key: 'location', name: 'Location', selected: true },
    { key: 'cost_center', name: 'Cost Center', selected: true },
    { key: 'profit_center', name: 'Profit Center', selected: true },
    { key: 'gl_accountant', name: 'GL Accountant', selected: true },
    { key: 'buyer_invoice', name: 'Buyer (invoice)', selected: true },
    { key: 'buyer_purchase_org', name: 'Buyer (Purch. Org) (IvS)', selected: true },
    { key: 'invoice', name: 'User defined 3 (invoice)', selected: true },
    { key: 'ifa_number', name: 'IFA Number', selected: true },
    { key: 'ifa_name', name: 'IFA Name', selected: true },
    { key: 'invoice_number', name: 'Number of Invoice', selected: true, important: true },
    { key: 'esn_source', name: 'ESN Source', selected: true },
    { key: 'description', name: 'Description', selected: true },

    { key: 'value_PVO', name: 'Value PVO', selected: true, type: 'currency', important: true },
    { key: 'value_OVO', name: 'Value OVO', selected: true, type: 'currency', important: true },

    { key: 'standard_category', name: 'Standard Category', selected: true, editable: true },
    { key: 'comment', name: 'Comment', selected: true, editable: true },
    { key: 'scm_star_reference', name: 'Reference in SCM Star', selected: true, editable: true },
    { key: 'feedback_user', name: 'Feedback User', selected: false },
    { key: 'feedback_timestamp', name: 'Feedback Timestamp', selected: false, type: 'date' }
];

// export const SCHEMA: MonitoringScheme[] = [
//     { key: 'id', name: 'ID', selected: false },
//     { key: 'division', name: 'Division', selected: true },
//     { key: 'business_unit', name: 'Business Unit', selected: true },
//     { key: 'business_segment', name: 'Business Segment', selected: true },
//     { key: 'cost_center', name: 'Cost Center', selected: true },
//     { key: 'profit_center', name: 'Profit Center', selected: true },
//     { key: 'plant', name: 'Plant', selected: true },
//     { key: 'location', name: 'Location', selected: true },
//     { key: 'gl_account', name: 'GL Account', selected: true },
//     { key: 'description', name: 'Description', selected: true },
//     { key: 'buyer_invoice', name: 'Buyer (invoice)', selected: true },
//     { key: 'buyer_ivs', name: 'Buyer (Purch. Org) (IvS)', selected: true },
//     { key: 'user_defined_invoice', name: 'User defined (invoice)', selected: true },
//     { key: 'fiscal_year', name: 'Fiscal Year', selected: true },
//     { key: 'esn_coporate', name: 'ESN Corporate', selected: true },
//     { key: 'esn_corporate_idesc', name: 'ESN Corporate Idesc', selected: true },
//     { key: 'country', name: 'Country', selected: true },
//     { key: 'ARE', name: 'ARE', selected: true },
//     { key: 'accbur', name: 'AccBuR', selected: true },
//     { key: 'swp', name: 'SWP', selected: true },
//     { key: 'category', name: 'Category', selected: true },
//     { key: 'ifa_number', name: 'IFA Number', selected: true },
//     { key: 'ifa_name', name: 'IFA Name', selected: true },
//     { key: 'po_number', name: 'PO Number', selected: true },
//     { key: 'reporting_currency', name: 'Reporting Currency', selected: true },
//     { key: 'esn_source', name: 'ESN Source', selected: true },
//     { key: 'standard_category', name: 'Standard Category', selected: true },
//     { key: 'comment', name: 'Comment', selected: true },
//     { key: 'scm_star_reference', name: 'Reference in SCM Star', selected: true }
// ];
