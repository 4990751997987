
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SCHEMA } from 'src/app/shared/schema';
import { UserService } from 'src/app/shared/services/user.service';
import { AccountantConfirmDialogComponent } from './dialogs/accountant-confirm-dialog.component';
import { AccountantDeclineDialogComponent } from './dialogs/accountant-decline-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { e } from 'src/app/shared/error-to-string';

@Component({
  selector: 'pwo-accountant',
  templateUrl: './accountant.component.html',
  styleUrls: ['./accountant.component.scss']
})
export class AccountantComponent implements OnInit {

  @Input() columns: string[];

  country: string;
  user: User;
  are: string;

  schema = SCHEMA;

  selectedARE: string;
  hint = ''; // TODO add hint text
  data: any[];
  confirmed = false;
  submitted = false;
  isLoading = true;

  constructor(
    public api: ApiService,
    public dialog: MatDialog,
    private notif: NotificationService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userService.user.subscribe(user => {
      if (user && user.settings) {
        this.user = user;
        this.init();
      }
    });

    this.route.params.subscribe(params => {
      this.country = params.country;
      this.are = params.are;
      this.init();
    });
  }

  init() {
    if (this.country && this.user && this.are) {
      // Reset
      this.hint = '';
      this.confirmed = false;
      this.submitted = false;
      this.data = undefined;
      this.selectedARE = this.are;
      this.loadData();
    }
  }

  loadData() {
    this.isLoading = true;
    console.log('loading data for ARE ' + this.selectedARE);
    this.api.getAccountantChanges(this.selectedARE).then(result => {

      const data = result.items;
      this.confirmed = result.confirmed;
      this.submitted = result.submitted;

      data.forEach(el => {
        (el as any).help = this.getHelpText(el);
      });

      this.data = data;
      console.log('data', this.data);
      this.isLoading = false;

    }).catch(err => {
      console.log(err);
      this.notif.error('Failed to load data: ' + e(err));
      this.data = undefined;
      this.isLoading = false;
    });
  }

  private getHelpText(el) {
    const prefix = 'Current status: ';
    switch (el.action) {
      case 'create':
        return prefix + 'created in Excel, not yet included in database';

      case 'delete':
        return prefix + 'deleted from Excel, included in database';

      case 'update':
        return prefix + 'included in Excel and database, but different versions';

      default:
        if (el.valid) {
          return prefix + 'same version included in Excel and database';
        } else {
          if (el.action === 'keep') {
            return 'Not validated, version in database won\'t be changed';
          } else {
            return 'Not validated, therefore won\'t be created in database';
          }
        }
    }
  }

  accountantConfirm() {
    const data = { ARE: this.selectedARE, month: this.api.formatMonth() };
    const dialogRef = this.dialog.open(AccountantConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // Confirm the changes
        this.api.confirmChanges(this.selectedARE, this.api.month.toISOString()).then(_ => {
          this.notif.info('Changes were confirmed');
          // this.loadData();
          this.loadData();
        }).catch(err => {
          this.notif.error('Failed to confirm changes: ' + e(err));
        });
      }
    });
  }

  accountantDecline() {
    const data = { ARE: this.selectedARE, month: this.api.formatMonth() };
    const dialogRef = this.dialog.open(AccountantDeclineDialogComponent, { data });
    dialogRef.afterClosed().subscribe(res => {
      if (!res.cancelled) {
        // Decline the changes
        this.api.declineChanges(this.selectedARE, res.comment, this.api.month.toISOString()).then(_ => {
          this.notif.info('Changes were declined, the local SCM can change the data for this ARE again');
          // this.loadData();
          this.loadData();
        }).catch(err => {
          this.notif.error('Failed to decline changes: ' + e(err));
        });
      }
    });
  }

}
