import { Component, OnInit, Input } from '@angular/core';
import { Progress } from './progress';

@Component({
  selector: 'pwo-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  width = 0;
  isError = false;

  @Input()
  set progress(prg: Progress<any>) {
    if (prg) {
      prg.progress(val => this.width = val)
        .then(_ => this.width = 100, _ => {
          this.isError = true;
          if (this.width === 0) {
            this.width = 100; // in case it errors immediately, show full bar
          }
          setTimeout(() => {
            // Reset
            this.width = 0;
            this.isError = false;
          }, 1000);
        });
    }
  }

  @Input() paused: boolean;

  constructor() { }

  ngOnInit() {
  }

}
