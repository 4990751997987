import { Injectable, EventEmitter } from '@angular/core';

export const DEFAULT_DURATION = 5000;

export interface NotificationOptions {
  /**
   * How long the notification is visible in milliseconds (default: 5000).
   */
  duration?: number;

  /**
   * If the notification should stay forever (default: false).
   * Hint: the duration is ignored if set to true.
   */
  persistent?: boolean;

  /**
   * If the notification can be closed by the user (default: true).
   */
  closeable?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notification = new EventEmitter();

  constructor() { }

  info(message: string, options: NotificationOptions = {}) {
    this.show({ message, options, type: 'info' });
  }

  // warn(message: string, options: NotificationOptions = {}) {
  //   this.show({ message, options, type: 'warning' });
  // } TODO

  error(message: string, options: NotificationOptions = {}) {
    // Errors are persistent by default
    if (options.persistent === undefined) { options.persistent = true; }
    this.show({message, options, type: 'error'});
  }

  private show(notification) {
    this.notification.next(notification);
  }
}
