import { Component, OnInit } from '@angular/core';
import { Role, fleet, monitoring, admin, systemAdmin } from '../shared/roles';
import { UserService } from '../shared/services/user.service';
import { User } from '../shared/models/user.model';

export interface Tile {
  title: string;
  background: string;
  link: string;
  description?: string;
  role?: Role;
}

@Component({
  selector: 'pwo-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  tiles: Tile[] = [
    { title: 'Fleet', background: 'fleet icon--list-view', link: '/upload', role: fleet }, // role: fleet
    { title: 'Monitoring', background: 'monitoring icon--facts-and-forecasts', link: '/monitoring', role: systemAdmin }, // role: monitoring
    { title: 'Authorization', background: 'auth icon--user-account', link: '/authorization', role: admin }, // role: admin
    { title: 'Admin', background: 'admin icon--settings', link: '/admin', role: systemAdmin }, // role: systemAdmin
    { title: 'Support', background: 'support icon--support', link: '/support' }, // everyone
    { title: 'LARS-Export', background: 'lars icon--file', link: '/lars', role: admin } // role: ?
  ];
  currentUser: User;
  isLoading = true;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.user.subscribe((currentuser) => {
      console.log('user is', currentuser);
      this.isLoading = false;
      this.currentUser = currentuser;
    });
  }

}
