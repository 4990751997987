import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';

interface Item {
  id: number;
  control: 'accept' | 'decline' | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class AcceptDeclineService {

  itemStore: Item[] = [];
  saveTimeout: number;

  itemsSaving: Item[] = [];

  config = {
    timeout: 5000
  };

  get isSaving() {
    return this.itemsSaving.length > 0;
  }

  get isDirty() {
    return this.isSaving || this.itemStore.length > 0;
  }

  constructor(private api: ApiService) { }

  set(item: Item) {
    if (!this.saveTimeout) {
      this.saveLater();
    }
    this.apply(item);
    this.check();
  }

  setMultiple(items: Item[]) {
    items.forEach(el => this.apply(el));
    this.save();
  }

  private check() {
    (window as any).dirty = this.isDirty;
  }

  private saveLater(timeout: number = this.config.timeout) {
    this.saveTimeout = window.setTimeout(() => this.save(), timeout);
  }

  async save() {
    if (this.saveTimeout) {
      window.clearTimeout(this.saveTimeout);
      this.saveTimeout = undefined;
    }
    if (this.isSaving) {
      // last save not yet finished, postpone
      this.saveLater();
      return;
    }
    this.itemsSaving = this.itemStore.slice();
    this.itemStore = [];
    this.check();

    try {
      // save items
      await this.apiCall();
    } catch (err) {
      // on error, try again later
      this.itemStore.push(...this.itemsSaving);
      this.saveLater();
    }
    this.itemsSaving = [];
    this.check();
  }

  private _mockupAPICall() {
    console.log('saving', this.itemsSaving.slice());
    return new Promise((resolve) => setTimeout(resolve, 500 + 500 * Math.random()));
  }

  private apiCall() {
    console.log('saving', this.itemsSaving.slice());
    const accepted = this.itemsSaving.filter(el => el.control === 'accept').map(el => el.id);
    const declined = this.itemsSaving.filter(el => el.control === 'decline').map(el => el.id);

    return this.api.saveChanges(accepted, declined);
  }

  private apply(item: Item) {
    const found = this.get(item);
    if (found) {
      found.control = item.control;
    } else {
      this.itemStore.push(item);
    }
  }

  private get(item: Item): Item {
    return this.itemStore.find(el => el.id === item.id);
  }
}
