import { Injectable } from '@angular/core';
import { AwsServerlessApiService } from 'src/app/shared/services/aws-serverless-api.service';
import { HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';
import { map, tap, last, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadValidationTableService {

  constructor(private aws: AwsServerlessApiService, private http: HttpClient) { }

  async upload(file: File) {
    const url = await this.getPresignedUrl();

    console.log('url', url);

    // const headers = new HttpHeaders({ 'Content-Type': file.type });
    // const req = new HttpRequest(
    //   'PUT',
    //   url,
    //   file,
    //   {
    //     headers: headers,
    //     reportProgress: true, //This is required for track upload process
    //   });
    // this.http.request(req).subscribe(console.log);
    // /*pipe(
    //   map(event => this.log(event, file)),
    //   tap(message => this.log(message)),
    //   last(), // return last (completed) message to caller
    //   catchError((err, caught) => this.log(err, caught, file) as any)
    // );*/


    // return 'foo';

    const headers = new HttpHeaders({
      'Content-Type': 'text/plain',
    });
    // const headers = new HttpHeaders({
    //   'Content-Type': 'text/plain',
    //   'x-amz-acl': 'bucket-owner-full-control'
    // });
    // const req = new HttpRequest('PUT', url, file, {
    //   headers: headers,
    //   reportProgress: true, // This is required for track upload process
    // });
    // const options = new RequestOptions({ headers: headers });

    console.log('body', file, headers);

    // const formData: FormData = new FormData();
    // formData.append('file', file);
    // const result = await this.http.put(url, formData, { headers }).toPromise();

    const result = await this.http.put(url, file, { headers }).toPromise();

    console.log('result', result);

    return result;
  }

  log(...params) {
    console.log(...params);
  }

  private getPresignedUrl() {
    return this.aws.fleet().get('/getPresignedURL').toPromise();
  }

  isUploading() {
    return this.aws.fleet().post('/validationTableStatus', { action: 'isUploading' }).toPromise();
  }

  clearStatus() {
    return this.aws.fleet().post('/validationTableStatus', { action: 'clear' }).toPromise();
  }

  setUploadingStatus() {
    return this.aws.fleet().post('/validationTableStatus', { action: 'setUploading' }).toPromise();
  }
}
