import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FilterValue } from '../../../pwo-filter.types';
import { ValueFilter } from '../../../pwo-filters';
import { PwoFilterCloudService } from '../../../pwo-filter-cloud.service';

@Component({
  selector: 'lib-single-select-filter',
  templateUrl: './single-select-filter.component.html',
  styleUrls: ['./single-select-filter.component.scss']
})
export class SingleSelectFilterComponent implements OnInit {

  @Input() filter: ValueFilter<any>;
  @Input() filterService: PwoFilterCloudService;

  @Output() close = new EventEmitter();

  allFilterValues: string[] = [];
  filterValues: string[] = [];
  selected;

  searchTerm = '';

  constructor() {}

  ngOnInit() {
    this.init();
  }

  init() {
    this.filterService.getFilterValues(this.filter).then(vals => {
      this.allFilterValues = vals;
      this.search();
      this.selected = vals.find(el => el === this.filter.value);
    });
  }

  search() {
    const s = this.searchTerm.toLowerCase();
    this.filterValues = this.allFilterValues.filter(el => str(el).toLowerCase().includes(s));
    if (this.filterValues.length === 1) {
      this.selected = this.filterValues[0];
    }

    function str(val: any) {
      if (!val) { return ''; }
      val = val + '';
      return val.length > 0 ? val : '(Empty)';
    }
  }

  apply() {
    this.filter.value = this.selected;
    this.filterService.applyFilter(this.filter);
    this.close.next();
  }

  clear() {
    this.selected = undefined;
    this.searchTerm = '';
    this.apply();
  }

}
