import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import * as Roles from 'src/app/shared/roles';
import { SCHEMA } from 'src/app/shared/schema';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { UserService } from 'src/app/shared/services/user.service';
import { User } from 'src/app/shared/models/user.model';
import { UploadPeriod } from 'src/app/shared/models/api-model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pwo-changes',
    templateUrl: './changes.component.html',
    styleUrls: ['./changes.component.scss']
})
export class ChangesComponent implements OnInit, OnDestroy {

    private routeSub: Subscription;
    countries = [];
    selectedCountry: string;

    areStatusPerCountry;

    ares = [];
    selectedAre: string[];

    columns = [
        ...SCHEMA
    ];
    selectedColumns = [];

    period: UploadPeriod;
    isSystemAdmin = false;
    isAccountant = false;
    roles = Roles;
    user: User;

    constructor(
        public api: ApiService,
        private userService: UserService,
        private dateHelper: DateHelperService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit() {
        this.areStatusPerCountry = undefined;
        this.userService.user.subscribe(user => {
            if (user && user.settings) {
                this.user = user;
                this.countries = Object.keys(user.settings.assignedAREs);
                this.isSystemAdmin = user.settings.isSystemAdmin;
            }
        });
        this.period = await this.dateHelper.checkUploadPeriod();

        this.routeSub = this.route.params.subscribe(params => {
            this.selectedCountry = params.country;
            this.selectedAre = params.are;
        });

        if (this.selectedCountry) {
            this.ares = this.user.settings.assignedAREs[this.selectedCountry];
            this.loadStatus();
        }
    }

    ngOnDestroy(): void {
        if (this.routeSub) {
          this.routeSub.unsubscribe();
        }
      }

    updateColumns(cols: string[]) {
        cols.push('valid', 'flags', 'action');
        if (!this.isAccountant) { cols.push('control'); }
        cols.push('help');

        this.selectedColumns = cols;
      }

    selectCountry(c) {
        this.areStatusPerCountry = undefined;
        this.selectedCountry = c;
        this.ares = this.user.settings.assignedAREs[this.selectedCountry];
        this.loadStatus();
    }

    selectAre(are) {
        this.selectedAre = are;
        this.router.navigate(['/review', this.selectedCountry, this.selectedAre]);
    }

    async loadStatus() {
        const statusCountryComplete = await this.api.scmStatus(this.ares);
        this.areStatusPerCountry = statusCountryComplete;
        this.areStatusPerCountry.areList = this.ares;
    }

}
