import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpHeaders, HttpParams, HttpErrorResponse, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { catchError, tap } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

import { AWSHttpService } from './aws-http.service';

export declare type HttpObserve = 'body' | 'events' | 'response';

export interface RequestOptions {
	body?: any;
	headers?: HttpHeaders | {
		[header: string]: string | string[];
	};
	context?: HttpContext;
	//observe?: 'body' | 'events' | 'response';
	params?: HttpParams | {
		[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
	};
	reportProgress?: boolean;
	withCredentials?: boolean;
	responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

@Injectable()
export class AWSHttpClient extends HttpClient {

	constructor(
		handler: HttpHandler,
		private awsHttpService: AWSHttpService
	) {
		super(handler);
	}

	request(first: string | HttpRequest<any>, url?: string, options: RequestOptions = {}): Observable<any> {
		console.log("REQUEST: ", first)
		if (first instanceof HttpRequest) {
			first = first.clone({
				url: this.awsHttpService.makeUrl(first.url)
			});
		} else {
			url = this.awsHttpService.makeUrl(url);
		}

		return super.request(first as any, url, options)
			.pipe(
				tap((e) => console.log("E", e)),
				catchError((err: HttpErrorResponse) => {
					console.log(err)
					this.awsHttpService.error$.next(err);

					return _throw(err);
				})
			);
	}
}
