export interface Field {
    key: string;
    name: string;
    selected?: boolean;
    headerCell?: string;
    width?: number;
    type?: string;
    filter?: string;
}

export const SCHEMA: Field[] = [
    {
        key: 'ARE',
        name: 'ARE',
        selected: true,
        headerCell: 'B4',
        width: 5
    },
    {
        key: 'cost_center',
        name: 'Cost Center',
        selected: true,
        headerCell: 'C4',
        width: 11
    },
    {
        key: 'vin_number',
        name: 'VIN Number',
        selected: true,
        headerCell: 'D4',
        width: 25,
        filter: 'search'
    },
    {
        key: 'ifa_number',
        name: 'IFA Number',
        selected: true,
        headerCell: 'E4',
        width: 11
    },
    {
        key: 'vert_nummer_lessor',
        name: 'Vehicle Leasing contract number',
        selected: false,
        headerCell: 'F4',
        width: 19
    },
    {
        key: 'leasing_supplier',
        name: 'Leasing supplier',
        selected: false,
        headerCell: 'G4',
        width: 35
    },
    {
        key: 'residual_value',
        name: 'Contracted (maximum) residual value guarantee for open leasing contracts',
        selected: true,
        headerCell: 'H4',
        width: 13
    },
    // {
    //     key: 'funding_method',
    //     name: 'Funding Method',
    //     selected: false
    // },
    {
        key: 'contract_start',
        name: 'Contract Start Date',
        selected: true,
        headerCell: 'I4',
        type: 'date',
        width: 18,
        filter: 'date'
    },
    {
        key: 'contract_end',
        name: 'Contract End Date',
        selected: false,
        headerCell: 'J4',
        type: 'date',
        width: 18,
        filter: 'date'
    },
    // {
    //     key: 'contract_duration',
    //     name: 'Contract Duration',
    //     selected: false
    // },
    {
        key: 'order_date',
        name: 'Order date of the vehicle',
        selected: false,
        headerCell: 'K4',
        type: 'date',
        width: 25,
        filter: 'date'
    },
    {
        key: 'interest_rate',
        name: 'Interest rate implicit in the lease in percentage',
        selected: true,
        headerCell: 'L4',
        width: 19
    },
    {
        key: 'funding_finance_lease',
        name: 'Funding / Finance Lease',
        selected: false,
        headerCell: 'M4',
        width: 21,
        filter: 'range'
    },
    {
        key: 'service_rate',
        name: 'Service rate (non finance lease rate)',
        selected: false,
        headerCell: 'N4',
        width: 11,
        filter: 'range'
    },
    // {
    //     key: 'executory_costs',
    //     name: 'Executory Costs',
    //     selected: false
    // },
    {
        key: 'currency',
        name: 'Currency',
        selected: true,
        headerCell: 'O4',
        width: 8
    },
    {
        key: 'frequency',
        name: 'Frequency',
        selected: true,
        headerCell: 'P4',
        width: 9
    },
    {
        key: 'frequency_unit',
        name: 'Frequency Unit',
        selected: true,
        headerCell: 'Q4',
        width: 14
    },
    {
        key: 'payment_form',
        name: 'Payment Form',
        selected: true,
        headerCell: 'R4',
        width: 14
    },
    {
        key: 'intercompany_lease',
        name: 'Intercompany (Sub-Group) Lease',
        selected: false,
        headerCell: 'S4',
        width: 21
    },
    {
        key: 'sublease_type',
        name: 'Sublease Type',
        selected: false,
        headerCell: 'T4',
        width: 16
    },
    {
        key: 'lease_incentives',
        name: 'Lease Incentive',
        selected: false,
        headerCell: 'U4',
        width: 16
    }
];
