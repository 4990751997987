import { Component, OnInit, ElementRef } from '@angular/core';
import { MenuService, MenuParams } from '../services/menu.service';


@Component({
  selector: 'pwo-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public params: MenuParams;

  constructor(public menuService: MenuService) { }

  ngOnInit() {
    this.menuService.change.subscribe(params => {
      this.params = params;
    });
  }

}

