import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[tooltip]'
})

export class TooltipDirective implements OnDestroy {

    constructor(
        private elementRef: ElementRef,
        private translate: TranslateService
    ) { }

    tooltip: any;
    elemPosition: any;
    tooltipOffset = 8;
    hideTimeoutId: number;
    showTimeoutId: number;

    // tslint:disable-next-line:no-input-rename
    @Input('tooltip') tooltipText = '';
    @Input() placement = 'top';
    @Input() delay = 0;
    // tslint:disable-next-line:no-input-rename
    @Input('show-delay') showDelay = 0;
    // tslint:disable-next-line:no-input-rename
    @Input('hide-delay') hideDelay = 500;
    // tslint:disable-next-line:no-input-rename
    @Input('z-index') zIndex = false;

    @HostListener('focusin')
    @HostListener('mouseenter')
    @HostListener('mousemove')
    onMouseEnter() {
        this.getElemPosition();

        if (!this.tooltip) {
            this.create();
            this.setPosition();
            this.show();
        }
    }

    @HostListener('focusout')
    @HostListener('mouseleave')
    @HostListener('mousedown')
    onMouseLeave() {
        this.hide();
    }

    ngOnDestroy() {
        if (this.showTimeoutId) {
            clearTimeout(this.showTimeoutId);
        }
        if (this.hideTimeoutId) {
            clearTimeout(this.hideTimeoutId);
        }
        if (this.tooltip && this.tooltip.parentNode) {
            this.tooltip.parentNode.removeChild(this.tooltip);
        }
    }

    getElemPosition() {
        this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect();
        const xValue = this.elemPosition.x;
        const yValue = this.elemPosition.y;

        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        if (yValue > (windowHeight * 0.8)) {
            this.placement = 'top';
        }
        if (yValue < (windowHeight * 0.1)) {
            this.placement = 'bottom';
        }
    }

    create() {
        if (!this.tooltipText) { return; }

        this.translate.get(this.tooltipText).subscribe((translatedTooltip: string) => {
            this.showDelay = this.delay || this.showDelay;
            this.tooltip = document.createElement('span');
            this.tooltip.className += 'ng-tooltip ng-tooltip-' + this.placement;
            // this.tooltip.textContent = translatedTooltip;
            this.tooltip.innerHTML = translatedTooltip;
            if (this.zIndex) { this.tooltip.style.zIndex = this.zIndex; }

            document.body.appendChild(this.tooltip);
        });

    }

    show() {
        if (this.showTimeoutId) {
            clearTimeout(this.showTimeoutId);
        }

        this.showDelay = this.delay || this.showDelay;
        this.showTimeoutId = window.setTimeout(() => {
            if (this.tooltip) {
                this.tooltip.className += ' ng-tooltip-show';
            }
        }, this.showDelay);
    }

    hide() {
        clearTimeout(this.showTimeoutId);

        if (this.hideTimeoutId) {
            clearTimeout(this.hideTimeoutId);
        }

        if (this.tooltip) {
            this.tooltip.classList.remove('ng-tooltip-show');
            this.hideTimeoutId = window.setTimeout(() => {
                this.tooltip.parentNode.removeChild(this.tooltip);
                this.tooltip = null;
            }, this.hideDelay);
        }
    }

    setPosition() {
        if (!this.tooltip) { return; }
        
        const elemHeight = this.elementRef.nativeElement.offsetHeight;
        const elemWidth = this.elementRef.nativeElement.offsetWidth;
        const tooltipHeight = this.tooltip.clientHeight;
        const tooltipWidth = this.tooltip.offsetWidth;
        const scrollY = window.pageYOffset;
        const rect = this.elementRef.nativeElement.getBoundingClientRect();

        if (this.placement === 'top') {
            this.tooltip.style.top = (rect.top + scrollY) - (tooltipHeight + this.tooltipOffset) - 10 + 'px';
        }

        if (this.placement === 'bottom') {
            this.tooltip.style.top = (rect.top + scrollY) + elemHeight + this.tooltipOffset + 10 + 'px';
        }

        if (this.placement === 'top' || this.placement === 'bottom') {
            this.tooltip.style.left = (rect.left + elemWidth / 2) - tooltipWidth / 2 + 'px';
        }

        if (this.placement === 'left') {
            this.tooltip.style.left = rect.left - tooltipWidth - this.tooltipOffset + 'px';
        }

        if (this.placement === 'right') {
            this.tooltip.style.left = rect.left + elemWidth + this.tooltipOffset + 'px';
        }

        if (this.placement === 'left' || this.placement === 'right') {
            this.tooltip.style.top = (rect.top + scrollY) + elemHeight / 2 - this.tooltip.clientHeight / 2 + 'px';
        }
    }
}
