import { Component, HostListener, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pwo-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss']
})
export class FilePickerComponent {

  @Output() selected = new EventEmitter<FileList>();

  isDragging = false;

  @HostListener('dragover', ['$event'])
  onDragOver(ev: Event) {
    ev.preventDefault();
    ev.stopPropagation();
    this.isDragging = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(ev: Event) {
    ev.preventDefault();
    ev.stopPropagation();
    this.isDragging = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.isDragging = false;

    const files = ev.dataTransfer.files;
    this.fileInput(files);
  }

  constructor() { }

  fileInput(files: FileList) {
    this.selected.next(files);
  }

}
