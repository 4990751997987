import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pwo-confirm-submit-dialog',
  template: `
<h4 mat-dialog-title>Submit changes?</h4>
<div mat-dialog-content>
  <p>Do you want to submit data for ARE {{ data.areList.join(', ') }} in {{ data.month }}?</p>
  <p style="color: red; font_weight: bold;">After you submitted, you cannot change the data for this month!</p>
  <!--<br><p><i>
    {{ message }}
  </i></p>-->
</div>
<div mat-dialog-actions class="button-container align-right" style="margin-top: 20px;">
  <button mat-button class="button" (click)="close(false)">Cancel</button>
  <button mat-button class="button" (click)="close(true)" cdkFocusInitial>Submit</button>
</div>
`
})
export class ConfirmSubmitDialogComponent {

  get message() { // TODO add message again
    const parts = [];

    format(this.data.create, 'created');
    format(this.data.update, 'updated');
    format(this.data.delete, 'deleted');
    format(this.data.keep, 'kept the same');

    function format(val, text) {
      if (val === 1) { parts.push('one item will be ' + text); }
      if (val > 1) { parts.push(val + ' items will be ' + text); }
    }

    if (parts.length > 1) {
      const last = parts.pop();
      return parts.join(', ') + ' and ' + last + ' in the database.';
    }
    if (parts.length === 1) {
      return parts[0] + ' in the database.';
    }
    return 'no changes are made in the database (you still have to submit)';
  }

  constructor(public dialogRef: MatDialogRef<ConfirmSubmitDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  close(res) {
    this.dialogRef.close(res);
  }
}
