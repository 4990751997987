import { Component, OnInit, Input } from '@angular/core';
import { countries } from '../../countries';

@Component({
  selector: 'pwo-are-list',
  templateUrl: './are-list.component.html',
  styleUrls: ['./are-list.component.scss']
})
export class AreListComponent implements OnInit {

  @Input() set assignedAREs(val) {
    console.log('got val', val);
    if (val) {
      this.getAREList(val);
    }
  }

  areList;
  count: number;
  expanded = false;

  constructor() { }

  ngOnInit() {
  }

  getAREList(val) {
    const arr = [];
    let count = 0;
    Object.keys(val).forEach(key => {
      count += val[key].length;
      const country = countries.find(c => c.code === key);
      arr.push({ country, AREs: val[key] });
    });
    this.areList = arr;
    this.count = count;
  }

}
