import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pwo-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  @Input()
  set status(status) {
    if (status) {
      const data = [];
      status.areList.forEach(ARE => {
        const inRevision = status.inRevision.includes(ARE);
        const submitted = status.submitted.find(el => el.ARE === ARE);
        const confirmed = status.confirmed.find(el => el.ARE === ARE);
        const comment = status.comments.find(el => el.ARE === ARE);
        const tooltip = this.getTooltip(inRevision, submitted, confirmed);
        data.push({ ARE, inRevision, submitted, confirmed, comment, tooltip });
      });
      this.data = data;
    } else {
      this.data = undefined;
    }
  }

  data;

  constructor() { }

  ngOnInit() {
  }

  getTooltip(inRevision, submitted, confirmed) {
    if (confirmed) {
      return 'confirmed by accountant';
    }
    if (submitted) {
      return 'submitted by SCM';
    }
    if (inRevision) {
      return 'uploaded by SCM';
    }
    return 'not yet uploaded';
  }
}
