import { Component, OnInit, Input } from '@angular/core';
import { UploadPeriod } from '../../models/api-model';
import * as moment from 'moment';

@Component({
  selector: 'pwo-upload-period-message',
  templateUrl: './upload-period-message.component.html',
  styleUrls: ['./upload-period-message.component.scss']
})
export class UploadPeriodMessageComponent implements OnInit {

  @Input() period: UploadPeriod;
  @Input() isSystemAdmin: boolean;
  @Input() action = 'submit';

  constructor() { }

  ngOnInit() {
    moment.relativeTimeRounding(Math.floor);
  }

  format(m: string) {
    return moment(m).format('lll');
  }

  getDuration(to: string) {
    return moment.duration(moment(to).diff(moment())).humanize();
  }

  isBefore() {
    return moment().isBefore(moment(this.period.periodStart));
  }

}
