import { Component, OnInit, ViewChild } from '@angular/core';
import { MultiValueFilter, ValueFilter, PwoFilterService } from 'pwo-filter';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { SubleaseItem, SubleaseConfigService } from 'src/app/services/sublease-config.service';
import { e } from 'src/app/shared/error-to-string';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog.component';
import { UserService } from 'src/app/shared/services/user.service';

interface SubleaseTableItem extends SubleaseItem {
  wasChanged?: boolean;
  isLoading?: boolean;
  error?: any;
}

@Component({
  selector: 'pwo-sublease-config',
  templateUrl: './sublease-config.component.html',
  styleUrls: ['./sublease-config.component.scss']
})
export class SubleaseConfigComponent implements OnInit {

  columns: string[] = ['ARE', 'country', 'ifa_number', 'sublease_type', 'control'];
  dataSource: MatTableDataSource<SubleaseTableItem>;

  filters = {
    ARE: new MultiValueFilter<string>('ARE'),
    country: new MultiValueFilter<string>('country'),
    ifa_number: new MultiValueFilter<string>('ifa_number'),
    sublease_type: new ValueFilter<string>('sublease_type')
  };

  get hasError() {
    return this.dataSource.data.filter(item => item.error).length > 0;
  }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  editMode = false;
  areList: { ARE: string, country: string }[];

  constructor(
    private service: SubleaseConfigService,
    private notif: NotificationService,
    private filterService: PwoFilterService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.user.subscribe(user => {
      this.areList = [];
      Object.keys(user.settings.assignedAREs).forEach(country => {
        const items = user.settings.assignedAREs[country].map(ARE => ({ ARE, country }));
        this.areList.push(...items);
      });
    });
    this.load();
  }

  load() {
    this.dataSource = undefined;
    this.service.getAll().then(data => {
      data.forEach(item => this.checkItem(item));
      this.dataSource = new MatTableDataSource(data);
      this.filterService.setDataSource(this.dataSource);
      this.dataSource.sort = this.sort;
    }).catch(err => this.notif.error('Failed to load data: ' + e(err)));
  }

  edit(item) {
    console.log('edit', item);
  }

  onInput(item: SubleaseTableItem, key: string, ev: Event) {
    const val = (ev.target as HTMLElement).textContent;
    item[key] = val;
    item.wasChanged = true;

    if (key === 'ARE') {
      const entry = this.areList.find(el => el.ARE === val);
      if (entry) {
        item.country = entry.country;
      } else {
        item.country = '';
      }
    }

    this.checkItem(item);
  }

  checkItem(item) {
    const errors: any = {};

    if (!item.country || item.country.length === 0) {
      errors.ARE = 'ARE not valid';
    }

    const ifa_number = item.ifa_number;
    if (ifa_number) {
      const ifa_err = [];
      if (ifa_number.length !== 0 && ifa_number.length !== 10) {
        ifa_err.push('IFA Number has to be empty or exactly 10 digits long');
      }
      if (!/^[0-9]+$/.test(ifa_number)) {
        ifa_err.push('IFA Number has to be numeric');
      }
      if (ifa_err.length > 0) { errors.ifa_number = ifa_err.join(', '); }
    }

    if (Object.keys(errors).length > 0) {
      item.error = errors;
    } else {
      item.error = false;
    }
  }

  create() {
    this.dataSource.data = [...this.dataSource.data, {
      id: undefined,
      ARE: '',
      country: '',
      ifa_number: '',
      sublease_type: 'No Sublease',

      wasChanged: true
    }];
  }

  delete(item: SubleaseTableItem) {
    if (item.id) {
      const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          item.isLoading = true;
          this.service.delete(item).then(_ => {
            // Remove item from view
            this.dataSource.data = this.dataSource.data.filter(el => el.id !== item.id);
          }).catch(err => {
            item.isLoading = false;
            this.notif.error('Error deleting item: ' + e(err));
          });
        }
      });
    } else {
      const index = this.dataSource.data.indexOf(item);
      if (index >= 0) {
        this.dataSource.data.splice(index, 1);
        this.dataSource.data = this.dataSource.data;
      }
    }
  }

  async saveAll() {
    const changed = this.dataSource.data.filter(item => item.wasChanged);
    try {
      await Promise.all(changed.map(item => this.save(item)));
      this.dataSource.data = this.dataSource.data;
      this.editMode = false;
    } catch (err) {
      this.notif.error('Error saving items: ' + e(err));
    }
  }

  save(item: SubleaseTableItem) {
    item.isLoading = true;
    return (item.id ? this.service.update(item) : this.service.add(item)).then(res => {
      // item.id = res.id;
      const index = this.dataSource.data.indexOf(item); // findIndex(el => el.id === item.id);
      this.dataSource.data[index] = res;
      // item = res;
      // item.isLoading = false;
      // item.wasChanged = false;
      // this.dataSource.data = this.dataSource.data;
    });
    // .catch(err => {
    //   item.isLoading = false;
    //   this.notif.error('Error saving item: ' + e(err));
    // });
  }

}
