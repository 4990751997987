import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountantComponent } from './changes/accountant/accountant.component';
import { AccountantDeclineDialogComponent } from './changes/accountant/dialogs/accountant-decline-dialog.component';
import { AccountantConfirmDialogComponent } from './changes/accountant/dialogs/accountant-confirm-dialog.component';
import { ItemPipe } from './changes/item.pipe';
import { ChangesComponent } from './changes/changes.component';
import { SharedModule } from '../../shared/shared.module';
import { PwoFilterCloudModule } from 'projects/pwo-filter-cloud/src/public_api';
import { ScmComponent } from './changes/scm/scm.component';
import { ConfirmSubmitDialogComponent } from './changes/scm/dialogs/confirm-submit-dialog.component';
import { ScmTableComponent } from './changes/scm/scm-table/scm-table.component';
import { AccountantTableComponent } from './changes/accountant/accountant-table/accountant-table.component';
import { StatusComponent } from './changes/scm/status/status.component';
import { ChangesDialogComponent } from './changes/scm/dialogs/changes-dialog.component';
import { PwoFilterModule } from 'pwo-filter';


@NgModule({
    declarations: [
        ChangesComponent,
        ItemPipe,
        ScmComponent,
        ScmTableComponent,
        ConfirmSubmitDialogComponent,
        AccountantComponent,
        AccountantTableComponent,
        ChangesDialogComponent,
        StatusComponent,
        AccountantConfirmDialogComponent,
        AccountantDeclineDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PwoFilterModule,
        PwoFilterCloudModule
    ]
})
export class ReviewModule { }
