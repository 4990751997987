import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { accountant } from 'src/app/shared/roles';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private notif: NotificationService,
    private router: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // return true
    return this.userService.authenticatedUser(next).then(user => {
      return this.checkPermission(user, next.routeConfig.path);
    });
  }

  private checkPermission(user, path) {
    if (user) {
      if (accountant(user)) {
        if (path !== 'upload') {
          return true;
        } else {
          this.notif.error('Accountant cannot access upload page', { persistent: true });
          this.router.navigateByUrl('/browse');
          return false;
        }
      } else {
        return true;
      }
    } else {
      if (path === '') {
        // Everyone can access landing page
        return true;
      } else {
        this.notif.error('No permission to access this part of the page', { persistent: true });
        this.router.navigateByUrl('/');
        return false;
      }
    }
  }

}
