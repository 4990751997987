import { Injectable } from '@angular/core';
import { User } from '../shared/models/user.model';
import { AwsServerlessApiService } from '../shared/services/aws-serverless-api.service';
import { UserService } from '../shared/services/user.service';
import { SubleaseType } from '../shared/models/api-model';

export interface SubleaseItem {
  id: number;
  ARE: string;
  country: string;
  ifa_number: string;
  sublease_type: SubleaseType;
}

@Injectable({
  providedIn: 'root'
})
export class SubleaseConfigService {

  user: User;

  constructor(private aws: AwsServerlessApiService, private userService: UserService) {
    this.userService.user.subscribe((currentuser) => {
      this.user = currentuser;
    });
  }

  getAll() {
    return this.aws.fleet().get('/subleaseConfig').toPromise();
  }

  add(item: SubleaseItem) {
    return this.aws.fleet().post('/subleaseConfig', item).toPromise();
  }

  update(item: SubleaseItem) {
    return this.aws.fleet().put('/subleaseConfig', item).toPromise();
  }

  delete(item: SubleaseItem) {
    return this.aws.fleet().delete('/subleaseConfig', item).toPromise();
  }
}
