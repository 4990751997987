import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'pwo-changes-dialog',
    template: `
<h3>Changes:</h3>
<div *ngFor="let el of data">
<p *ngIf="el.val1 && el.val2">
    {{ el.schema.name }} was changed from '{{ el.val1 | format:el.schema.type }}' to '{{ el.val2 | format:el.schema.type }}'
</p>
<p *ngIf="el.val1 && !el.val2">{{ el.schema.name }} ('{{ el.val1 | format:el.schema.type }}') was removed</p>
<p *ngIf="!el.val1 && el.val2">{{ el.schema.name }} was added: '{{ el.val2 | format:el.schema.type }}'</p>
</div>
<br>
<button mat-button class="button" (click)="close()">Close</button>
`,
})
export class ChangesDialogComponent {

    constructor (
        public dialogRef: MatDialogRef<ChangesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    close(): void {
        this.dialogRef.close();
    }
}
