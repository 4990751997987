import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  userLanguageSelected = 'en';

  constructor() { }

  getCurrentLanguage() {
    return this.userLanguageSelected;
  }

  setCurrentLanguage(lang) {
    this.userLanguageSelected = lang;
  }
}
