import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { User, getDefaultUserSettings } from '../models/user.model';
import { ConsoleLoggerService } from './console-logger.service';
import { AwsServerlessApiService } from './aws-serverless-api.service';
//import { AuthGmsClientService } from '../../auth/services/auth-gms-client.service';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public _user: User = undefined;



  constructor(private logger: ConsoleLoggerService, private aws: AwsServerlessApiService, private auth: AuthService
    //private auth: AuthGmsClientService)
  ){
  }

  authenticatedUser(next?: ActivatedRouteSnapshot): Promise<User> {
    // auth guard methode
    //if (!this.auth.isAuthenticated()) {
    //   const redirectPath = next ? '/' + next.url.map(seg => seg.path).join('/') : location.pathname;
    //   this.auth.login(redirectPath);
    // }

    if (this._user) {
      return Promise.resolve(this._user);
    }

    // if (environment.useDummyUser) {
    //   this._user = {
    //     'firstName': 'Tim',
    //     'lastName': 'Spickermann',
    //     'gid': 'Z003TD9T',
    //     'mail': 'tim.spickermann@siemesn.com',
    //     'settings': {
    //       'isLocalAdmin': false,
    //       'isSystemAdmin': true,
    //       'role': 'scm',
    //       'assignedAREs': { 'HUN': [ '5304' ] }
    //     }
    //   };
    //   return Promise.resolve(this._user);
    // }

    return new Promise((resolve, _) => {
      this.aws.fleet().post('/user', {}).toPromise().then(this.expandDefaultSettings).then(user => {
        this.setCurrentUser(user);
        resolve(this._user);
      }).catch(err => {
        console.log('get user settings failed', err);
        this.setCurrentUser(undefined);
        resolve(this._user);
      });
    });
  }

  fetchUser(gid: string) {
    return this.aws.fleet().post('/getByGID', { gid }).toPromise().then(this.expandDefaultSettings);
  }

  fetchAllUsers() {
    return this.aws.fleet().get('/getAllUsers').toPromise();
  }

  putUser(mode: 'create' | 'edit', user: User) {
    (user as any).mode = mode;
    return this.aws.fleet().put('/user', user).toPromise();
  }

  expandDefaultSettings(user: User) {
    if (user) {
      user.settings = Object.assign(getDefaultUserSettings(), user.settings);
    }
    return user;
  }

  // to overwrite role
  setCurrentUser(user: User) {
    this.logger.log('Current User is: ', user);
    this._user = user;
    this.user.next(user);
  }

  public get isAdmin() {
    return this._user && (this._user.settings.isSystemAdmin || this._user.settings.isLocalAdmin);
  }

}
