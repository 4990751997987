import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'pwo-ie-dialog',
    template: `
<div class="container">
    <div class="icon">
        <i class="material-icons">error</i>
    </div>
    <div class="message">
        <p>You are using Internet Explorer which is <b>not supported</b> by the IFRS16 Data Collector.</p>
        <p>Please use Google Chrome to use this website.</p><br>
    </div>
</div>
<div class="button-container align-right">
<button class="button grey-ghost" (click)="dialogRef.close()">Ignore</button>
</div>
`,
    styles: [`
.container {
    display: flex;
}
.icon i {
    font-size: 3rem;
    color: #75899a;
    padding-right: 20px;
}
.message {
    flex: 1;
}
.message p {
    font-size: 1.2em;
    line-height: 1.2em;
}
`]
})
export class IEDialogComponent {
    constructor(public dialogRef: MatDialogRef<IEDialogComponent>) {}
}
