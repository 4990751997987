import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pwo-accountant-confirm-dialog',
  template: `
<h4 mat-dialog-title>Confirm changes?</h4>
<div mat-dialog-content>
  <p>Do you want to confirm the changes of the local SCM for ARE {{ data.ARE }} in {{ data.month }}?</p>
  <p style="color: red; font_weight: bold;">You cannot undo this action!</p>
</div>
<div mat-dialog-actions class="button-container align-right" style="margin-top: 20px;">
  <button mat-button class="button" (click)="close(false)">Cancel</button>
  <button mat-button class="button" (click)="close(true)" cdkFocusInitial>Confirm</button>
</div>
`
})
export class AccountantConfirmDialogComponent {

  constructor(public dialogRef: MatDialogRef<AccountantConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  close(res) {
    this.dialogRef.close(res);
  }
}
