import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface Column {
  key: string;
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'pwo-display-columns',
  templateUrl: './display-columns.component.html',
  styleUrls: ['./display-columns.component.scss']
})
export class DisplayColumnsComponent {

  columns = [];
  @Input() set availableColumns(cols: Column[]) {
    this.columns = cols.slice();
    this.apply();
  }
  @Output() selected = new EventEmitter();

  constructor() { }

  cancelEvent(ev: Event) {
    ev.stopPropagation();
  }

  apply() {
    const cols = this.columns.filter(c => c.selected).map(el => el.key);
    this.selected.next(cols);
  }

}
