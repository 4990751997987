import { Injectable } from '@angular/core';
import { FilterRecord } from 'projects/pwo-filter-cloud/src/public_api';
import { User } from 'src/app/shared/models/user.model';
import { AwsServerlessApiService } from 'src/app/shared/services/aws-serverless-api.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SignedUrlResponse, ParsePayload, UploadStatus, StatusPayload, SaveCommentPayload, AggregatePayload } from './api-model';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { uuid } from 'src/app/shared/uuid';
import { pauseFor } from 'src/app/shared/pause-for';
import { MonitoringRow, SCHEMA, MonitoringFeedback } from '../schema';

export const MonitoringContentType = 'application/vnd.ms-excel.sheet.macroenabled.12';
// export const MonitoringContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  user: User;
  month: Date;

  get aws() {
    return this.awsApi.monitoring();
  }

  constructor(private awsApi: AwsServerlessApiService, private apiService: ApiService, private userService: UserService,
    private dateHelper: DateHelperService, private http: HttpClient, private notif: NotificationService) {
    this.userService.user.subscribe((currentuser) => {
      this.user = currentuser;
    });
    this.month = this.dateHelper.convertToMonth(new Date());
  }

  getData(records: FilterRecord[], page: number, limit: number, sort: string, sortDir: string) {
    const payload = {
      tenant: 'Siemens',
      month: this.getMonth(),
      page: page,
      limit: limit,
      filters: records,
      sort, sortDir,
    };
    return this.aws.post('/monitoring/get', payload).toPromise();
  }

  async getStatus(): Promise<UploadStatus> {
    const payload: StatusPayload = {
      month: this.getMonth(),
      tenant: 'Siemens'
    };
    return this.aws.post('/monitoring/status', payload).toPromise();
  }

  storeOriginalValues(el: MonitoringRow) {
    const editableKeys = SCHEMA.filter(s => s.editable).map(s => s.key);
    editableKeys.forEach(k => el[k + '_original'] = el[k]);
    return el;
  }

  saveComment(row: MonitoringRow) {
    const payload: SaveCommentPayload = {
      id: row.id,
      ARE: row.ARE,
      standard_category: row.standard_category,
      comment: row.comment,
      scm_star_reference: row.scm_star_reference,
      tenant: 'Siemens'
    };
    return this.aws.post('/monitoring/saveComment', payload).toPromise();
  }

  getFilterValues(filter: FilterRecord, records: FilterRecord[]) {
    const payload = {
      month: this.getMonth(),
      filter, records
    };
    return this.aws.post('/monitoring/filterValues', payload).toPromise();
  }

  getPresignedUrl(): Promise<SignedUrlResponse> {
    return this.aws.get('/monitoring/getUploadURL').toPromise();
  }

  private getMonth() {
    return this.month.toISOString().substring(0, 7);
  }

  async upload(file: File, type: 'PVO' | 'OVO') {
    const presigned = await this.getPresignedUrl();

    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const result = await this.http.put(presigned.url, file, { headers }).toPromise();
    console.log('result', result);

    // Parse uploaded file:
    const callback_id = uuid();
    const parsePayload: ParsePayload = {
      tenant: 'Siemens',
      month: this.getMonth(),
      callback_id,
      file: presigned.key,
      type
    };
    await this.aws.post('/monitoring/parse', parsePayload).toPromise();
    await pauseFor(5000); // wait until parse sets the status to isUploading // FIXME bad practice

    // const msg = await this.apiService.waitFor(callback_id, { maxTries: 30, interval: 30 }) as ParseResponse;

    // return msg;
  }

  async aggregate() {
    const callback_id = uuid();
    const payload: AggregatePayload = {
      month: this.getMonth(),
      callback_id,
      tenant: 'Siemens'
    };
    await this.aws.post('/monitoring/aggregate', payload).toPromise();
    return this.apiService.waitFor(callback_id, { maxTries: 10, interval: 30 });
  }

  clearMonth() {
    return this.aws.post('/monitoring/clearMonth', { month: this.getMonth() }).toPromise();
  }
}
