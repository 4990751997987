import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SharedModule } from '../shared/shared.module';
import { PwoFilterModule } from 'pwo-filter';
import { UserFormComponent } from './user-management/user-form/user-form.component';
import { AreSelectComponent } from './user-management/are-select/are-select.component';
import { UploadValidationTableComponent } from './upload-validation-table/upload-validation-table.component';
import { BdmeDialogComponent } from './bdme-dialog/bdme-dialog.component';
import { UserListComponent } from './user-list/user-list.component';
import { AreDialogComponent } from './user-management/are-dialog/are-dialog.component';
import { LarsExportComponent } from './lars-export/lars-export.component';

@NgModule({
    declarations: [
        AdminComponent,
        UserManagementComponent,
        UserFormComponent,
        AreSelectComponent,
        UploadValidationTableComponent,
        BdmeDialogComponent,
        UserListComponent,
        AreDialogComponent,
        LarsExportComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PwoFilterModule
    ]
})
export class AdminModule { }
