import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectCountriesDialogComponent } from './select-countries-dialog/select-countries-dialog.component';
import { DateHelperService } from '../../shared/services/date-helper.service';
import { UserService } from '../../shared/services/user.service';
import { checkCountriesResponse, validateResponse, UploadPeriod } from '../../shared/models/api-model';
import { e } from '../../shared/error-to-string';
import { ParserService } from './parser.service';

@Component({
  selector: 'pwo-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  state: 'select' | 'upload' = 'select';
  progress;
  statusText = 'Uploading file';

  paused = false;

  period: UploadPeriod;
  isSystemAdmin = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private notif: NotificationService,
    public dialog: MatDialog,
    private dateHelper: DateHelperService,
    private userService: UserService,
    private parser: ParserService
  ) { }

  async ngOnInit() {
    this.period = await this.dateHelper.checkUploadPeriod();
    this.userService.user.subscribe(user => {
      if (user && user.settings) {
          this.isSystemAdmin = user.settings.isSystemAdmin;
      }
    });
  }

  onSelected(files: FileList) {
    if (files.length > 0) {
      const file = files[0];
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.parse(file);
      } else {
        this.notif.error('File is not an Excel file (' + file.type + ')');
      }
    } else {
      this.notif.error('Got no file');
    }
  }

  async parse(file: File) {
    this.state = 'upload';
    this.statusText = 'Parsing Excel file';

    let parsed;
    try {
      parsed = await this.parser.parse(file);
    } catch (err) {
      this.notif.error('Error during file parsing: ' + e(err), { persistent: true });
      this.state = 'select';
      return;
    }
    console.log(parsed.length);

    this.statusText = 'Uploading data';

    let nonce: string;
    try {
      nonce = await this.apiService.uploadData(parsed);
    } catch (err) {
      this.notif.error(`Error during file upload: ${e(err)}`);
      this.state = 'select';
      return;
    }

    this.statusText = 'Evaluating data';

    let data: checkCountriesResponse;
    try {
      data = await this.apiService.getCountries(nonce);
    } catch (err) {
      console.log(err);
      this.notif.error('Error during data parsing: ' + e(err), { persistent: true });
      this.state = 'select';
      return;
    }

    this.statusText = 'Successfully parsed Excel file, waiting for user input';

    this.paused = true;
    const dialogRef = this.dialog.open(SelectCountriesDialogComponent, { data });
    const result: { countries: string[], AREs: string[] } = await dialogRef.afterClosed().toPromise();
    this.paused = false;

    if (!result) {
      this.notif.info('Cancelled upload, nothing changed');
      this.state = 'select';
      return;
    }
    const aresToUpload = result.AREs;

    this.statusText = 'Validating data';

    // let added: validateResponse[];
    let nonceVal: string;
    try {
      nonceVal = await this.apiService.validate(aresToUpload);
    } catch (err) {
      this.notif.error('Error during data validation: ' + e(err), { persistent: true });
      this.state = 'select';
      return;
    }

    let nonceGC: string;
    try {
      nonceGC = await this.apiService.generateChangesForAREs(aresToUpload);
    } catch (err) {
      this.notif.error('Error during generation of changes: ' + e(err), { persistent: true });
      this.state = 'select';
      return;
    }

    // const count = added.map(el => el.affectedRows).reduce((a, b) => a + b);
    this.notif.info(`Successfully added uploaded items`);

    this.router.navigateByUrl('/review/' + result.countries[0] + '/' + result.AREs[0]);
  }

}
