import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { MenuService } from '../services/menu.service';
import { UserService } from '../../shared/services/user.service';
import { LanguageMenuComponent } from '../menu/menu-subcomponents/language-menu/language-menu.component';
import { UserSettingsComponent } from '../menu/menu-subcomponents/user-settings/user-settings.component';
import { User } from 'src/app/shared/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { filter } from 'rxjs/operators';
import { scm, admin, systemAdmin, monitoring, Role } from 'src/app/shared/roles';

interface Link {
  selector: string;
  label: string;
  role?: Role;
  dragover?: boolean;
}
interface Links {
  '': Link[];
  fleet: Link[];
  monitoring: Link[];
  admin: Link[];
}

@Component({
  selector: 'pwo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  currentUser: User;
  // public route: string;
  public languageSelected = 'en';
  // navLinks = [
  //   { selector: '/upload', label: 'nav.upload', dragover: true },
  //   { selector: '/review', label: 'nav.review', role: scm },
  //   { selector: '/browse', label: 'nav.browse' },
  //   { selector: '/admin', label: 'nav.admin', hide: true },
  //   { selector: '/authorization', label: 'Authorization', hide: true },
  //   { selector: '/sublease-config', label: 'Sublease', hide: true }
  // ];

  module: keyof Links = 'fleet';
  links: Links = {
    '': [],
    'fleet': [
      { selector: '/upload', label: 'nav.upload', dragover: true, role: scm }, // role: scm
      { selector: '/review', label: 'nav.review' }, // role: none
      { selector: '/browse', label: 'nav.browse', role: systemAdmin }, // role: none
      { selector: '/sublease-config', label: 'Sublease', role: systemAdmin }, // role: admin
      // { selector: '/authorization', label: 'Authorization', role: admin },
      // { selector: '/admin', label: 'Admin', role: systemAdmin }
    ],
    'monitoring': [
      { selector: '/monitoring', label: 'Monitoring', role: systemAdmin } // role: monitoring
    ],
    'admin': [
      { selector: '/authorization', label: 'Authorization', role: admin }, // role: admin
      { selector: '/lars', label: 'LARS-Export', role: systemAdmin }, // role: admin
      { selector: '/admin', label: 'Admin', role: systemAdmin } // role: systemAdmin
    ]
  };
  isAdmin = false;

  get isMonthChanged() {
    return this.api.formatMonth() !== this.api.formatMonth(new Date());
  }

  settingsRole = admin;

  constructor(
    private router: Router,
    private menuService: MenuService,
    private userService: UserService,
    public api: ApiService
  ) {
    this.userService.user.subscribe((currentuser) => {
      this.currentUser = currentuser;

      setTimeout(() => {
        const uploadEl = document.querySelector('.drag-over');
        if (uploadEl) {
          uploadEl.addEventListener('dragover', () => this.onDragOver());
        }
      });

    });

  }

  ngOnInit() {
    const lang = localStorage.getItem('language');
    if (lang !== null) {
      this.languageSelected = lang;
    }

    this.router.events.pipe(filter(e => e instanceof RoutesRecognized)).subscribe((ev: RoutesRecognized) => {
      this.module = ev && ev.state && ev.state.root && ev.state.root.firstChild && ev.state.root.firstChild.data &&
      ev.state.root.firstChild.data['module'];
    });
  }

  ngOnDestroy() {
    const uploadEl = document.querySelector('.drag-over');
    if (uploadEl) {
      uploadEl.removeEventListener('dragover', () => this.onDragOver());
    }
  }

  onDragOver() {
    if (this.router.url !== '/upload') {
      this.router.navigateByUrl('/upload');
    }
  }

  public changeLanguage(lang) {
    localStorage.setItem('language', lang);
    window.location.reload();
  }

  public openUserMenu() {
    const title = this.currentUser.firstName + ' ' + this.currentUser.lastName;
    this.menuService.open({ title, component: UserSettingsComponent });
  }

  public openLanguageMenu() {
    this.menuService.open({ title: 'Language', component: LanguageMenuComponent });
  }

  isActive(link) {
    if (link.valid && (this.router.url.indexOf(link.selector) > -1)) {
      return true;
    }
    return false;
  }

}
