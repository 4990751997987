import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin/admin.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
//import { AuthGmsClientCallbackComponent } from './auth/components/auth-gms-client-callback.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { ViewComponent } from './fleet/browse/view/view.component';
import { ChangesComponent } from './fleet/review/changes/changes.component';
import { SubleaseConfigComponent } from './fleet/sublease-config/sublease-config.component';
import { UploadComponent } from './fleet/upload/upload.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MonitoringTableComponent } from './monitoring/components/monitoring-table/monitoring-table.component';
import { AdminAuthGuard } from './shared/services/admin-auth.guard';
import { LarsExportComponent } from './admin/lars-export/lars-export.component';

@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
      window.location.href = route.data['externalUrl'];
      return false;
  }
}

const routes: Routes = [

  // LANDING PAGE
  { path: '', component: LandingPageComponent, canActivate: [ AuthGuard ], data: { module: '' } },

  // FLEET
  { path: 'upload', component: UploadComponent, canActivate: [ AuthGuard ], data: { module: 'fleet' } },
  { path: 'review', component: ChangesComponent, canActivate: [ AuthGuard ], data: { module: 'fleet' } },
  // { path: 'review/:country', component: ChangesComponent, canActivate: [ AuthGuard ], data: { module: 'fleet' } },
  { path: 'review/:country/:are', component: ChangesComponent, canActivate: [ AuthGuard ], data: { module: 'fleet' } },
  { path: 'browse', component: ViewComponent, canActivate: [ AuthGuard ], data: { module: 'fleet' } },
  { path: 'browse/:vin_number', component: ViewComponent, canActivate: [ AuthGuard ], data: { module: 'fleet' } },
  { path: 'sublease-config', component: SubleaseConfigComponent, canActivate: [ AdminAuthGuard ], data: { module: 'fleet' } },

  // MONITORING
  { path: 'monitoring', component: MonitoringTableComponent, canActivate: [ AuthGuard ], data: { module: 'monitoring' } },

  // ADMIN
  { path: 'admin', component: AdminComponent, canActivate: [ AdminAuthGuard ], data: { module: 'admin' } },
  { path: 'authorization', component: UserManagementComponent, canActivate: [ AdminAuthGuard ], data: { module: 'admin' } },
  { path: 'lars', component: LarsExportComponent, canActivate: [ AdminAuthGuard ], data: { module: 'admin' } },
  // { path: 'authorization', component: UserListComponent, canActivate: [ AdminAuthGuard ], data: { module: 'admin' } },
  // { path: 'authorization/new', component: UserManagementComponent, canActivate: [ AdminAuthGuard ], data: { module: 'admin' } },
  // { path: 'authorization/edit/:gid', component: UserManagementComponent, canActivate: [ AdminAuthGuard ], data: { module: 'admin' } },

  // AUTHENTICATION
  //{ path: 'auth/callback', component: AuthGmsClientCallbackComponent },

  // SUPPORT
  {
    path: 'support',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://jira.gms.siemens.cloud/servicedesk/customer/portal/25'
    }
  }
];

// routes.push(...fleetRoutes.map(el => Object.assign(el, { data: { module: 'fleet' } })));

// const routes: Routes = [
//   { path: '', redirectTo: '/browse', pathMatch: 'full' , canActivate: [ AuthGuard ]},
//   { path: 'upload', component: UploadComponent, canActivate: [ AuthGuard ] },

//   { path: 'review', component: ChangesComponent, canActivate: [ AuthGuard ] },
//   { path: 'review/:country', component: ChangesComponent, canActivate: [ AuthGuard ] },

//   { path: 'browse', component: ViewComponent, canActivate: [ AuthGuard ] },
//   { path: 'browse/:vin_number', component: ViewComponent, canActivate: [ AuthGuard ] },

//   { path: 'admin', component: AdminComponent, canActivate: [ AdminAuthGuard ] },

//   { path: 'authorization', component: UserManagementComponent, canActivate: [ AdminAuthGuard ] },
//   { path: 'sublease-config', component: SubleaseConfigComponent, canActivate: [ AdminAuthGuard ] },

//   { path: 'auth/callback', component: AuthGmsClientCallbackComponent }
// ];

@NgModule({
  providers: [RedirectGuard],
  imports: [RouterModule.forRoot(routes, { useHash: false, initialNavigation: 'enabledNonBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
