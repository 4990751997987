import { Component, OnInit, EventEmitter, ViewChild, Input, Output } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateHelperService } from '../../services/date-helper.service';

@Component({
  selector: 'pwo-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {

  @ViewChild('picker', { static: false }) datePicker: MatDatepicker<any>;

  _month: Date;
  @Input() set month(val: Date) {
    this._month = new Date(val.getUTCFullYear(), val.getUTCMonth());
  }
  @Input() disabled = false;
  @Input() monitoring = false;
  @Output() monthChange = new EventEmitter<Date>();

  minDate: Date;

  constructor(private dateHelper: DateHelperService) { }

  ngOnInit() {
    if (this.monitoring) {
      this.minDate = new Date (2020, 0, 1);
    }
  }

  onMonthSelected(event) {
    const month = this.dateHelper.convertToMonth(event);
    this.monthChange.emit(month);
    this.datePicker.close();
  }

}
