import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../../services/api.service';
import { SCHEMA, Field } from '../../../shared/schema';
import { ViewTableComponent } from './view-table/view-table.component';
import { ExportService } from '../../../shared/services/export.service';
import { ProgressDialogComponent } from '../../../shared/components/progress.component';
import { NotificationService } from '../../../shared/services/notification.service';
import { UserService } from '../../../shared/services/user.service';
import { e } from '../../../shared/error-to-string';
import { ExportDialogComponent } from '../export-dialog/export-dialog.component';
import { Filter } from '../../../../../projects/pwo-filter-cloud/src/public_api';
import * as moment from 'moment';

@Component({
  selector: 'pwo-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  months = [];
  selectedMonth = '';

  columns = [
    { key: 'country', name: 'Country', selected: true },
    { key: 'month', name: 'Month', selected: true },
    ...SCHEMA
  ];
  selectedColumns = [];

  showChanges = false;

  @ViewChild(ViewTableComponent, { static: false }) table: ViewTableComponent;

  constructor(
    public api: ApiService,
    public exportService: ExportService,
    public dialog: MatDialog,
    public notif: NotificationService,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.updateColumns();
  }

  cancelEvent(ev: Event) {
    ev.stopPropagation();
  }

  updateColumns() {
    const cols = this.columns.filter(el => el.selected).map(el => el.key);
    // cols.push('valid', 'flags', 'action');
    // if (!this.isAccountant) { cols.push('control'); }
    // cols.push('help');

    if (this.showChanges) {
      // insert at beginning
      cols.splice(0, 0, 'action', 'flags');
    }

    this.selectedColumns = cols;
  }

  toggle() {
    this.showChanges = !this.showChanges;
    this.updateColumns();
  }

  exportProd() {
    const filters = Object.values(this.table.filters).filter((f: Filter) => f.isApplied);
    const exportDialog = this.dialog.open(ExportDialogComponent, {
      data: {
        filters,
        showChanges: this.showChanges,
        count: (this.table.summary as any).total,
        selectedMonth: moment.utc(this.table.filters['month'].value).toDate()
      }
    });
    exportDialog.afterClosed().subscribe(([ev, filter]) => {
      if (ev) {
        // Export dataset with chosen filter
        const progress = this.dialog.open(ProgressDialogComponent);
        (this.showChanges ? this.exportService.exportChanges(filter) : this.exportService.exportProd(filter)).then(res => {
          progress.close();
          this.notif.info(res);
        }).catch(err => {
          progress.close();
          this.notif.error(e(err));
        });
      }
    });
  }

}
