import { Component, OnInit, ViewChild, Input, EventEmitter, Output, ChangeDetectionStrategy, NgZone, DoCheck, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PwoFilterService, DateFilter, ValueFilter, MultiValueFilter, Filter } from 'pwo-filter';
import { SCHEMA } from 'src/app/shared/schema';
import { FLAG_TOOLTIPS } from '../../flag-tooltips';
import { ChangesDialogComponent } from '../../scm/dialogs/changes-dialog.component';

@Component({
  selector: 'pwo-accountant-table',
  templateUrl: './accountant-table.component.html',
  styleUrls: ['./accountant-table.component.scss']
})
export class AccountantTableComponent implements OnInit {

  _columns: string[];
  @Input() set columns(cols) {
    this._columns = cols.filter(el => el !== 'control');
    this.generateFilters();
    setTimeout(() => this.onScroll(), 0);
    this.calculateStickyOffsets();
  }
  get columns() {
    return this._columns;
  }

  _data: any[] = [];
  @Input() set data(data) {
    if (!data) { data = []; }
    this._data = data;
    this.setData(data);
    setTimeout(() => this.onScroll(), 0);
    window.requestAnimationFrame(() => this.calculateStickyOffsets());
  }
  get data() {
    return this._data;
  }

  @Input() isInRevision = false;

  @Output() acceptAll = new EventEmitter();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  dataSource: MatTableDataSource<any>;

  scrolledToEnd = false;
  flagTooltips = FLAG_TOOLTIPS;

  schema = SCHEMA;

  filters: { [key: string]: Filter } = {};

  constructor(private filterService: PwoFilterService, private ngZone: NgZone, public dialog: MatDialog) { }

  ngOnInit() {
  }

  setData(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.filterService.setDataSource(this.dataSource);
    setTimeout(() => {
      // Wait one tick so that Angular can render the table
      this.dataSource.sort = this.sort;
    });
  }

  selectAll(status) {
    this.dataSource.data.forEach(el => {
      if (el.action && el.valid !== 0) {
        el.control = status;
      }
    });
    this.acceptAll.next();
  }

  trackByFn(index, item) {
    return item.key;
  }

  onScroll(ev?) {
    const el = ev ? ev.target : document.querySelector('.table-container');
    if (!el) { return; }

    if (el.scrollWidth - el.scrollLeft === el.offsetWidth) {
      if (!this.scrolledToEnd) { this.scrolledToEnd = true; }
    } else {
      if (this.scrolledToEnd) { this.scrolledToEnd = false; }
    }
  }

  date(d: string) {
    return new Date(d).toLocaleDateString();
  }

  generateFilters() {
   console.log('generate filters');
    // this.filters = {};
    this.columns.forEach(col => {
      if (!this.filters[col]) {
        const filter = this.schema.find(el => el.key === col);
      //  console.log('new filter ' + col, filter);
        if (filter && filter.type === 'date') {
          this.filters[col] = new DateFilter(col);
        } else if (col === 'valid') {
          this.filters[col] = new ValueFilter<number>(col);
        } else {
          this.filters[col] = new MultiValueFilter<string>(col);
        }
      }
    });

    if (this.dataSource) {
      setTimeout(() => {
        // Workaround to distribute filter values again for the recreated filter components.
        // It seems that with the implementation of MatTable, the recreation cannot be prevented!
        this.filterService.search = this.filterService.search;
      }, 0);
    }
  }

  private calculateStickyOffsets() {
    this.ngZone.runOutsideAngular(() => {
      const sticky_columns = [ 'valid', 'flags', 'action', 'help' ];
      setTimeout(() => {
        for (const col of sticky_columns) {
          const colsAfter = Array.from(document.querySelectorAll(`.mat-column-${col} ~ th`));
          const offset = colsAfter.map(el => (el as HTMLElement).offsetWidth).reduce((a, b) => a + b, 0);
          document.querySelectorAll(`.mat-column-${col}`).forEach(el => (el as HTMLElement).style.right = offset + 'px');
        }
      }, 200);
    });
  }

  showChangesDialog(el) {
    el.diff.forEach(d => {
      d.schema = SCHEMA.find(s => s.key === d.col);
    });
    this.dialog.open(ChangesDialogComponent, { data: el.diff });
  }

}
