import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BrowseModule } from './browse/browse.module';
import { ReviewModule } from './review/review.module';
import { UploadComponent } from './upload/upload.component';
import { SelectCountriesDialogComponent } from './upload/select-countries-dialog/select-countries-dialog.component';
// import { FleetRoutingModule } from './fleet-routing.module';
import { SubleaseConfigComponent } from './sublease-config/sublease-config.component';
import { ConfirmDeleteDialogComponent } from './sublease-config/confirm-delete-dialog.component';
import { PwoFilterModule } from 'pwo-filter';

@NgModule({
    declarations: [
        UploadComponent,
        SelectCountriesDialogComponent,
        SubleaseConfigComponent,
        ConfirmDeleteDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        // FleetRoutingModule,
        BrowseModule,
        ReviewModule,
        PwoFilterModule
    ]
})
export class FleetModule { }
