import { Component, OnInit, ViewChild, Input, NgZone, EventEmitter, AfterViewInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FLAG_TOOLTIPS } from '../../flag-tooltips';
import { SCHEMA } from 'src/app/shared/schema';
import { PwoFilterCloudService, DateFilter, ValueFilter, MultiValueFilter, Filter } from 'projects/pwo-filter-cloud/src/public_api';
import { ChangesDialogComponent } from '../dialogs/changes-dialog.component';
import { AcceptDeclineService } from '../../../accept-decline.service';

@Component({
  selector: 'pwo-scm-table',
  templateUrl: './scm-table.component.html',
  styleUrls: ['./scm-table.component.scss'],
})
export class ScmTableComponent implements OnInit, AfterViewInit {

  _columns: string[];
  @Input() set columns(cols) {
    this._columns = cols;
    this.generateFilters();
    setTimeout(() => this.onScroll(), 0);
    this.calculateStickyOffsets();
  }
  get columns() {
    return this._columns;
  }

  // _data: any[] = [];
  // @Input() set data(data) {
  //   if (!data) { data = []; }
  //   this._data = data;
  //   this.setData(data);
  //   setTimeout(() => this.onScroll(), 0);
  //   window.requestAnimationFrame(() => this.calculateStickyOffsets());
  // }
  // get data() {
  //   return this._data;
  // }

  @Input() canEdit = false;
  @Input() isInRevision = false;
  @Input() filterService: PwoFilterCloudService;
  @Input() isLoading: boolean;

  @Output() pageDone = new EventEmitter();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  filters: { [key: string]: Filter } = {};
  dataSource = new MatTableDataSource();

  scrolledToEnd = false;
  flagTooltips = FLAG_TOOLTIPS;
  schema = SCHEMA;

  constructor(private ngZone: NgZone, public dialog: MatDialog, private controlService: AcceptDeclineService) { }

  ngOnInit() {
    this.filterService.data.subscribe(data => {
      this.dataSource.data = data;
      this.calculateStickyOffsets();
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.filterService.triggerFilter();
    });
  }

  // setData(data: any[]) {
  //   this.dataSource.data = data;
  // }

  hasControl(el) {
    return el.action && el.valid !== 0;
  }

  selectAll(status) {
    this.dataSource.data.forEach((el: any) => {
      if (this.hasControl(el)) {
        el.control = status;
      }
    });
    this.controlService.setMultiple(this.dataSource.data.map(this.convert));
    this.checkPage();
  }

  onControl(el) {
    this.controlService.set(this.convert(el));
    this.checkPage();
  }

  convert(el) {
    return { id: el.id, control: el.control };
  }

  checkPage() {
    const isDone = this.dataSource.data.every((el: any) => !this.hasControl(el) || el.control);
    this.pageDone.emit(isDone ? 'done' : undefined);
  }

  trackByFn(index, item) {
    return item.key;
  }

  onScroll(ev?) {
    const el = ev ? ev.target : document.querySelector('.table-container');
    if (!el) { return; }

    if (el.scrollWidth - el.scrollLeft === el.offsetWidth) {
      if (!this.scrolledToEnd) { this.scrolledToEnd = true; }
    } else {
      if (this.scrolledToEnd) { this.scrolledToEnd = false; }
    }
  }

  date(d: string) {
    return new Date(d).toLocaleDateString();
  }

  private generateFilters() {
   console.log('generate filters');
    // this.filters = {};
    this.columns.forEach(col => {
      if (!this.filters[col]) {
        const filter = this.schema.find(el => el.key === col);
      //  console.log('new filter ' + col, filter);
        if (filter && filter.type === 'date') {
          this.filters[col] = new DateFilter(col);
        } else if (col === 'valid') {
          this.filters[col] = new ValueFilter<number>(col);
        } else {
          this.filters[col] = new MultiValueFilter<string>(col);
        }
      }
    });
  }

  private calculateStickyOffsets() {
    this.ngZone.runOutsideAngular(() => {
      const sticky_columns = [ 'valid', 'flags', 'action', 'control', 'help' ];
      setTimeout(() => {
        for (const col of sticky_columns) {
          const colsAfter = Array.from(document.querySelectorAll(`.mat-column-${col} ~ th`));
          const offset = colsAfter.map(el => (el as HTMLElement).offsetWidth).reduce((a, b) => a + b, 0);
          document.querySelectorAll(`.mat-column-${col}`).forEach(el => (el as HTMLElement).style.right = offset + 'px');
        }
      }, 200);
    });
  }

  showChangesDialog(el) {
    el.diff.forEach(d => {
      d.schema = SCHEMA.find(s => s.key === d.col);
    });
    this.dialog.open(ChangesDialogComponent, { data: el.diff });
  }

}
