import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MultiValueFilter, PwoFilterService } from 'pwo-filter';

export interface AreSelectItem {
  ARE: string;
  country: string;
  accountability?: string;
}

@Component({
  selector: 'pwo-are-select',
  templateUrl: './are-select.component.html',
  styleUrls: ['./are-select.component.scss']
})
export class AreSelectComponent implements OnInit {

  columns: string[] = ['select', 'ARE', 'country', 'accountability'];
  dataSource: MatTableDataSource<AreSelectItem>;

  @Input() selection: SelectionModel<AreSelectItem>;

  @Input() set areList(val: AreSelectItem[]) {
    if (val) {
      this.init(val);
    }
  }

  filters = {
    ARE: new MultiValueFilter<string>('ARE'),
    country: new MultiValueFilter<string>('country'),
    accountability: new MultiValueFilter<string>('accountability')
  };

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private filterService: PwoFilterService) { }

  ngOnInit() {
  }

  init(list: AreSelectItem[]) {
    this.dataSource = new MatTableDataSource<AreSelectItem>(list);
    this.dataSource.sort = this.sort;
    this.filterService.setDataSource(this.dataSource);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isFilteredAllSelected() {
    if (!this.dataSource) { return false; }

    for (const row of this.dataSource.filteredData) {
      if (!this.selection.isSelected(row)) { return false; }
    }

    return true;
  }

  isFilteredIndeterminate() {
    if (!this.dataSource) { return false; }

    if (this.isFilteredAllSelected()) { return false; }
    for (const row of this.dataSource.filteredData) {
      if (this.selection.isSelected(row)) { return true; }
    }

    return false;
  }

  masterToggle() {
    if (this.isFilteredAllSelected()) {
      // this.selection.clear();
      this.dataSource.filteredData.forEach(row => this.selection.deselect(row));
    } else {
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: AreSelectItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} ARE ${row.ARE}`;
  }

}
