import { Component } from '@angular/core';

@Component({
  selector: 'pwo-progress-dialog',
  template: `<mat-spinner></mat-spinner>`
})
export class ProgressDialogComponent {

  constructor() { }

}
