/**
 * This function tries to transform different Errors to a comprehensible text.
 *
 * @param err Some kind of Error object
 */
export function e(err: any): string {
    if (typeof err === 'string') {
        return err;
    }
    if (typeof err.error === 'string') {
        return err.error;
    }
    if (typeof err.message === 'string') {
        return err.message;
    }
    return err;
}
