
/**
 * This functions returns a Promise that resolves after the provided duration (in ms).
 *
 * @param duration_ms duration in milliseconds
 */
export function pauseFor(duration_ms: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(() => resolve(), duration_ms);
    });
}
