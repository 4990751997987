import { Component, OnInit, ViewChild, Input, AfterViewInit, AfterViewChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PwoFilterCloudService, DateFilter, ValueFilter, MultiValueFilter, RangeFilter, SearchFilter } from 'projects/pwo-filter-cloud/src/public_api';
import { SCHEMA, Field } from 'src/app/shared/schema';
import { ApiService } from 'src/app/services/api.service';
import { getDataResponse } from 'src/app/shared/models/api-model';
import { Page } from 'src/app/shared/components/paginator/paginator.component';
import { ChangesDialogComponent } from 'src/app/fleet/review/changes/scm/dialogs/changes-dialog.component';
import { FormatPipe } from 'src/app/shared/pipes/format.pipe';
import { Subject } from 'rxjs';

@Component({
  selector: 'pwo-view-table',
  templateUrl: './view-table.component.html',
  styleUrls: ['./view-table.component.scss']
})
export class ViewTableComponent implements OnInit, AfterViewInit {

  dateFilterChanged: Subject<string> = new Subject<string>();

  _columns: string[];
  @Input() set columns(cols) {
    this._columns = cols;
    this.generateFilters();
  }
  get columns() {
    return this._columns;
  }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  schema: Field[] = [
    { key: 'country', name: 'Country' },
    { key: 'month', name: 'Month', type: 'month' },
    { key: 'action', name: 'Action' },
    { key: 'flags', name: 'Flags' },
    ...SCHEMA
  ];
  filters = {};
  filterService: PwoFilterCloudService;

  private _showChanges: boolean;
  @Input() set showChanges(val: boolean) {
    const old = this._showChanges;
    this._showChanges = val;

    if (old !== undefined && old !== val) {
      this.dataSource.data = undefined;
      this.filterService.triggerFilter();
    }
  }
  get showChanges() {
    return this._showChanges;
  }

  page = 1;
  limit = 50;
  pages: Page[] = [];
  summary = {};
  sortQuery = undefined;

  userAres: string[] = ['7092', '402t'];
  filteredAres: string[] = [];
  areInputText: string = '';

  constructor(private api: ApiService, private formatPipe: FormatPipe, public dialog: MatDialog) {
    this.filteredAres = this.userAres;
   }

  ngOnInit() {
    // this.filterService = new PwoFilterCloudService(
    //   r => this.api.getData(r, this.showChanges, this.page, this.limit,
    //     this.sort.direction === '' ? undefined : this.sort.active, this.sort.direction),
    //   (f, r) => this.api.getFilterValues(f, r, this.showChanges),
    //   false
    // );
    // this.filterService.data.subscribe(data => this.setData(data));
    // this.filterService.apply.subscribe(() => this.page = 1);
    
    console.log('TEST: ', this.filteredAres);
    this.initData(50);
  }

  filterAreList() {
    this.filteredAres = this.userAres.filter(option => 
      option.toLowerCase().includes(this.areInputText.toLowerCase())
    );
  }

  async initData(limit: number) {
    const items = await this.api.getData(limit);
    this.dataSource.data = items;
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.filterService.triggerFilter();
    });

    // Set current month as default filter
    const f = this.filters['month'];
    if (f) {
      this.filterService.getFilterValues(f).then(vals => {
        console.log(vals);
        const month = this.api.month.toISOString();
        if (vals.includes(month)) {
          f.value = month;
        } else {
          f.value = vals.pop();
        }
        this.filterService.applyFilter(f);
      });
    }
  }

  onPaginationChange(limit) {
    // this.page = page;
    // this.filterService.triggerFilter(); // reload the data
    this.initData(limit);
  }

  setData(data: getDataResponse) {
    console.log('data', data);

    this.pages = data.pages.map(el => ({ number: el, done: false }));
    const start = (this.page - 1) * this.limit + 1;
    this.summary = {
      page: this.page,
      start: start,
      end: Math.min(start + this.limit - 1, data.total),
      total: data.total
    };

    this.dataSource.data = data.items;
  }

  trackByFn(index, item) {
    return item.key;
  }

  generateFilters() {
    this.columns.forEach(col => {
      if (!this.filters[col]) {
        const filter = this.schema.find(el => el.key === col);
        if (col === 'month') {
          this.filters[col] = new ValueFilter<Date>(col);
          this.filters[col].transform = (val) => this.formatPipe.transform(val, 'month');
        } else if (filter && filter.filter === 'date') {
          this.filters[col] = new DateFilter(col);
        } else if (filter && filter.filter === 'range') {
          this.filters[col] = new RangeFilter(col);
        } else if (filter && filter.filter === 'search') {
          this.filters[col] = new SearchFilter(col);
        } else {
          this.filters[col] = new MultiValueFilter<string>(col);
          if (col === 'flags') { this.filters[col].delimiter = ','; }
        }
      }
    });
  }

  showChangesDialog(el) {
    el.diff.forEach(d => {
      d.schema = SCHEMA.find(s => s.key === d.col);
    });
    this.dialog.open(ChangesDialogComponent, { data: el.diff });
  }

  getSelectedMonth() {
    return this.filters['month'].value;
  }

}
