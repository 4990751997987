// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { LogLevel, OpenIdConfiguration } from "angular-auth-oidc-client";

export const environment = {
  production: false,
  isDebugMode: true,
  // scdService: 'https://pwo-db.gms.siemens.com:8007/Default.aspx',
  // webapi: 'https://876ah8xb06.execute-api.eu-central-1.amazonaws.com/dev',
  webapi: "https://bs4e7i66rj.execute-api.eu-central-1.amazonaws.com/prod",
  webapiMonitoring:
    "https://yw1d04uetc.execute-api.eu-central-1.amazonaws.com/dev",
  // webapiMonitoring: 'https://uneu74py4l.execute-api.eu-central-1.amazonaws.com/prod',
  useDummyUser: false,
  uploadBucket: "ifrs16-xslx-files",
  authentication: {
    unauthorizedRoute: "/auth/unauthorized",
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: "z3kBaoz6G9wCaSHtkeoiNT4ezAoOJOJY",
    authority: 'https://prd-op.siemens.auth0app.com/',
    authWellknownEndpointUrl: 'https://prd-op.siemens.auth0app.com/.well-known/openid-configuration',
    scope: "openid profile email offline_access",
    responseType: "code",
    silentRenew: true,
    useRefreshToken: true,
    autoUserInfo: true,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    renewUserInfoAfterTokenRenew: true,
    ignoreNonceAfterRefresh: true,
    logLevel: LogLevel.Debug,
    useCustomAuth0Domain: true,
    customParamsAuthRequest: {
      display: [
        "sup",
        "an-IFRS16DC",
        "extra-fields",
        "cidp-off",
        "apple-off",
        "flender-off",
        "azure-social-common-off",
        "facebook-off",
        "github-off",
        "gitlab-off",
        "google-off",
        "linkedin-off",
        "twitter-off",
        "windows-off",
      ].join("|"),
    },
  } as OpenIdConfiguration,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
