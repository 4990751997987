import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmSubmitDialogComponent } from './dialogs/confirm-submit-dialog.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SCHEMA } from 'src/app/shared/schema';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { e } from 'src/app/shared/error-to-string';
import { ProgressDialogComponent } from 'src/app/shared/components/progress.component';
import { PwoFilterCloudService } from 'projects/pwo-filter-cloud/src/public_api';
import { ScmTableComponent } from './scm-table/scm-table.component';
import { AcceptDeclineService } from '../../accept-decline.service';
import { Page } from 'src/app/shared/components/paginator/paginator.component';
import { EventEmitter } from 'stream';

@Component({
  selector: 'pwo-scm',
  templateUrl: './scm.component.html',
  styleUrls: ['./scm.component.scss']
})
export class ScmComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() columns: string[];
  @Input() are: string;
  @Input() country: string;

  user: User;

  changeItems: any[];

  data: any[];
  isLoading = true;
  submitRunning = false;

  status;
  allChecked = false;

  schema = SCHEMA;

  limit = 5000;
  pages: Page[] = [];
  page = 1;
  summary;

  selectedAres: string[];

  filterService: PwoFilterCloudService;
  @ViewChild(ScmTableComponent, { static: false }) table: ScmTableComponent;

  constructor(
    public api: ApiService,
    public dialog: MatDialog,
    private notif: NotificationService,
    private userService: UserService,
    private route: ActivatedRoute,
    public controlService: AcceptDeclineService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.filterService = new PwoFilterCloudService(
      r => this.loadData(r),
      (f, r) => this.api.getChangesFV(this.selectedAres, f, r),
      false
    );
    this.filterService.apply.subscribe(() => this.page = 1);

  }

  ngAfterViewInit() {
    this.userService.user.subscribe(user => {
      if (user && user.settings) {
        this.user = user;
        this.init();
      }
    });

    this.route.params.subscribe(params => {
      this.country = params.country;
      this.are = params.are;
      this.init();
    });
  }

  ngOnDestroy() {
    console.log('changes table on destroy');
    // this.saveChangesQuery().catch(err => {
    //   this.notif.error('Error: the changes you made to Accept/Decline on the last page are lost (' + e(err) + ')');
    // });
  }

  async loadData(filters) {
    this.isLoading = true;

    if (this.controlService.isDirty) {
      // Save accept/decline configuration before loading data so that check page in the backend returns the correct values
      await this.controlService.save();
    }

    const sortDir = this.table.sort.direction;
    const sort = sortDir === '' ? undefined : this.table.sort.active;

    const res = await this.api.getPaginatedChanges(this.selectedAres, this.page, this.limit, filters, sort, sortDir);

    this.changeItems = res.items;

    this.pages = res.pages;
    this.updateAllChecked();
    const start = (this.page - 1) * this.limit + 1;
    this.summary = {
      page: this.page,
      start: start,
      end: Math.min(start + this.limit - 1, res.total),
      total: res.total
    };

    res.items.forEach(el => {
      (el as any).help = this.getHelpText(el);
      (el as any).buttonTooltip = this.getButtonTooltip(el);
    });

    this.isLoading = false;
    return res.items;
  }

  init() {
    if (this.are && this.user && this.country) {

      this.status = undefined;
      this.allChecked = false;
      this.selectedAres = [this.are];
      this.page = 1;

      if (this.selectedAres) {
        this.filterService.triggerFilter();
        this.loadStatus();
      } else {
        this.clear();
      }
      this.cd.detectChanges();
    }
  }

  clear() {
    this.isLoading = false;
    this.pages = [];
    this.status = undefined;
    this.allChecked = false;
    this.data = undefined;
    this.summary = undefined;
  }

  onPagination(page) {
    this.page = page;
    this.filterService.triggerFilter();
  }

  async loadStatus() {
    this.status = undefined;
    const status = await this.api.scmStatus(this.selectedAres);
    this.status = status;
    this.status.areList = this.selectedAres;
  }

  private getHelpText(el) {
    const prefix = 'Current status: ';
    switch (el.action) {
      case 'create':
        return prefix + 'created in Excel, not yet included in database';

      case 'delete':
        return prefix + 'deleted from Excel, included in database';

      case 'update':
        return prefix + 'included in Excel and database, but different versions';

      default:
        if (el.valid) {
          return prefix + 'same version included in Excel and database';
        } else {
          if (el.action === 'keep') {
            return 'Not validated, version in database won\'t be changed';
          } else {
            return 'Not validated, therefore won\'t be created in database';
          }
        }
    }
  }

  private getButtonTooltip(el) {
    // [ accept tooltip, decline tooltip ]
    switch (el.action) {
      case 'create':
        return [ 'create item in database', 'ignore item' ];

      case 'delete':
        return [ 'delete item from database', 'keep item as is in database' ];

      case 'update':
        return [ 'update item in database', 'keep item as is in database' ];
    }
  }

  async submit() {
    this.submitRunning = true;

    if (this.controlService.isDirty) {
      await this.controlService.save();
    }

    const summary = { areList: this.status.inRevision, month: this.api.formatMonth() };

    const dialogRef = this.dialog.open(ConfirmSubmitDialogComponent, { data: summary });
    const res = await dialogRef.afterClosed().toPromise();
    if (res) {
      // Submit the changes
      const progress = this.dialog.open(ProgressDialogComponent, { disableClose: true });
      this.api.submit(this.api.month.toISOString(), this.country, this.status.inRevision) // this.user.settings.assignedAREs[this.country]
      .then((status: any) => {
        this.notif.info(status);
        progress.close();
        this.loadStatus();
        this.filterService.triggerFilter(); // reload data
        this.submitRunning = false;
      }).catch(err => {
        console.log(err);
        progress.close();
        this.notif.error('Submit failed: ' + e(err));
      });
    } else {
      this.submitRunning = false;
    }
  }

  onPageDone(done) {
    const page = this.pages.find(el => el.number === this.page);
    if (page) { page.done = !!done; }
    this.updateAllChecked();
  }

  updateAllChecked() {
    const unchecked = this.changeItems.filter(item => !item.control) as any[]; // Items with no control set are unchecked; Control can be 'accept' or 'decline'
    this.allChecked = unchecked.filter(item => item.action).length === 0; // Items without an action (e.g. 'create' or 'update' or 'delete') should not be counted as these are invalid
  }

}
