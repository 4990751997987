import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AwsServerlessApiService } from './aws-serverless-api.service';
import { UploadPeriod } from '../models/api-model';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor(private awsApi: AwsServerlessApiService) { }

  convertToMonth(d: Date) {
    // UTC to avoid timezone errors
    return moment.utc([d.getFullYear(), d.getMonth()]).toDate();
  }

  formatMonth(month: Date) {
    return moment.utc(month).format('MMMM YYYY');
  }

  async checkUploadPeriod(): Promise<UploadPeriod> {
    return this.awsApi.fleet().get('/uploadPeriod').toPromise();
  }
}
