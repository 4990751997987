import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PwoFilterCloudService, Filter, DateFilter, ValueFilter, MultiValueFilter } from 'projects/pwo-filter-cloud/src/public_api';
import { MonitoringService } from '../../services/monitoring.service';
import { SCHEMA, StandardCategory, MonitoringRow } from '../../schema';
import { Summary, Page } from 'src/app/shared/components/paginator/paginator.component';
import { UploadComponent } from '../upload/upload.component';
import { UserService } from '../../../shared/services/user.service';
import { User } from '../../../shared/models/user.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { e } from 'src/app/shared/error-to-string';
import * as moment from 'moment';

@Component({
  selector: 'pwo-monitoring-table',
  templateUrl: './monitoring-table.component.html',
  styleUrls: ['./monitoring-table.component.scss']
})
export class MonitoringTableComponent implements OnInit, AfterViewInit {

  columns = SCHEMA.filter(el => el.key !== 'id');
  selectedColumns = [];

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  filters: { [key: string]: Filter } = {};
  filterService: PwoFilterCloudService;
  dataSource = new MatTableDataSource<MonitoringRow>();

  isLoading = false;

  page = 1;
  limit = 100;
  summary: Summary;
  pages: Page[];

  standardCategories = Object.values(StandardCategory);
  currentUser: User;

  get displayMonth() {
    return moment(this.monitoringService.month).format('MMMM YYYY');
  }

  constructor(public monitoringService: MonitoringService, private dialog: MatDialog,
    private userService: UserService, private notif: NotificationService) { }

  ngOnInit() {
    this.userService.user.subscribe(u => this.currentUser = u);

    this.filterService = new PwoFilterCloudService(
      (r) => this.getData(r),
      (f, r) => this.getFV(f, r)
    );

    this.filterService.data.subscribe(data => {
      console.log(data);
      this.dataSource.data = data;
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.load();
    });
  }

  async getData(r) {
    this.isLoading = true;

    // Save comments
    await this.saveAll();

    return this.monitoringService.getData(r, this.page, this.limit, this.sort.active, this.sort.direction)
      .then(res => {
        this.isLoading = false;

        const start = (this.page - 1) * this.limit + 1;
        this.summary = {
          page: this.page,
          start: start,
          end: Math.min(start + this.limit - 1, res.total),
          total: res.total
        };
        this.pages = res.pages;

        return res.items.map(el => this.monitoringService.storeOriginalValues(el));
    });
  }

  load() {
    this.filterService.triggerFilter();
  }

  getFV(f, r) {
    return this.monitoringService.getFilterValues(f, r);
  }

  onSelected(cols) {
    this.selectedColumns = [...cols, 'save'];
    this.generateFilters();
  }

  onPagination(page: number) {
    this.page = page;
    this.load();
  }

  private generateFilters() {
    this.selectedColumns.forEach(col => {
      if (col !== 'save' && !this.filters[col]) {
        const filter = this.columns.find(el => el.key === col);
        if (filter && filter.type === 'date') {
          this.filters[col] = new DateFilter(col);
        } else if (col === 'valid') {
          this.filters[col] = new ValueFilter<number>(col);
        } else {
          this.filters[col] = new MultiValueFilter<string>(col);
        }
      }
    });
  }

  isChange(el: MonitoringRow) {
    for (const def of SCHEMA.filter(s => s.editable)) {
      if (el[def.key] !== el[def.key + '_original'] && (typeof el[def.key] !== 'string' || (el[def.key] as any).length > 0)) {
        return true;
      }
    }
    return false;
  }

  hasChanges() {
    return this.dataSource.data.some(this.isChange);
  }

  saveAll() {
    return Promise.all(this.dataSource.data.filter(this.isChange).map(el => this.save(el)));
  }
  async save(el: MonitoringRow) {
    try {
      const res = await this.monitoringService.saveComment(el);
      Object.assign(el, res); // save updated values
      el = this.monitoringService.storeOriginalValues(el); // reset change detection
    } catch (err) {
      console.log(err);
      this.notif.error('Error: ' + e(err));
    }
  }

  openUploadDialog() {
    const dialogRef = this.dialog.open(UploadComponent);
    dialogRef.afterClosed().subscribe(() => this.load()); // reload data
  }

}
