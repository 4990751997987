import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BdmeDialogComponent } from '../bdme-dialog/bdme-dialog.component';

@Component({
  selector: 'pwo-lars-export',
  templateUrl: './lars-export.component.html',
  styleUrls: ['./lars-export.component.scss']
})
export class LarsExportComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openBDME() {
    this.dialog.open(BdmeDialogComponent);
  }

}
