import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { FilterValue } from '../../../pwo-filter.types';
import { MultiValueFilter } from '../../../pwo-filters';
import { PwoFilterCloudService } from '../../../pwo-filter-cloud.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'lib-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectFilterComponent implements OnInit, OnDestroy {

  @Input() filter: MultiValueFilter<any>;
  @Input() filterService: PwoFilterCloudService;

  @Output() close = new EventEmitter();

  selection = new SelectionModel<string>(true, []);

  originalFVs: string[] = [];
  allFilterValues: string[] = [];
  filterValues: string[] = [];

  searchTerm = '';

  allSelected = true;
  fewSelected = false;

  addToFilter = false;
  isLoading = false;

  constructor() {}

  ngOnInit() {
    this.selection.changed.subscribe(() => {
      const count = this.selection.selected.length;
      this.allSelected = count === this.filterValues.length;
      this.fewSelected = !this.allSelected && count > 0;
    });
    this.init();
  }

  ngOnDestroy() {
    this.selection.changed.unsubscribe();
  }

  search() {
    const s = this.searchTerm.toLowerCase();
    this.filterValues = this.allFilterValues.filter(el => str(el).toLowerCase().includes(s));
    this.selection.clear();
    this.selection.select(...this.filterValues);

    function str(val: any) {
      if (!val) { return ''; }
      val = val + '';
      return val.length > 0 ? val : '(Empty)';
    }
  }

  init() {
    this.isLoading = true;
    this.searchTerm = '';
    this.filterService.getFilterValues(this.filter).then(vals => {
      this.originalFVs = vals.slice();
      vals = this.flatten(vals);

      this.allFilterValues = vals;
      this.filterValues = vals;
      this.selection.clear();

      if (!this.filter.isActive()) {
        // Filter not set means all values are selected
        this.selection.select(...this.filterValues);
      } else {
        // Restore selection with available options
        const sel = this.filter.flattened.filter(f => this.filterValues.includes(f));
        this.selection.select(...sel);
      }

      this.isLoading = false;
    });
  }

  flatten(vals: string[]) {
    if (this.filter.delimiter) {
      vals = [].concat(...vals.map(el => el && el.split(this.filter.delimiter)));
      vals = Array.from(new Set(vals));
      vals.sort();
    }
    return vals;
  }
  expand(vals: string[]) {
    if (this.filter.delimiter) {
      vals = this.originalFVs.filter(val => vals.some(a => val && val.includes(a)));
    }
    return vals;
  }

  apply() {
    if (this.selection.selected.length === this.allFilterValues.length) {
      // Remove filter if all options are selected
      this.selection.clear();
    } else if (this.searchTerm.length > 0 && this.addToFilter) {
      // Select old selection
      this.selection.select(...this.filter.flattened);
    }
    this.filter.flattened = this.selection.selected;
    this.filter.selected = this.expand(this.selection.selected);
    this.filterService.applyFilter(this.filter);
    this.close.next();
  }

  toggleAll() {
    if (this.allSelected) {
      this.selection.clear();
    } else {
      this.selection.select(...this.filterValues);
    }
  }

}
