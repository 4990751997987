import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pwo-confirm-submit-dialog',
  template: `
<h4 mat-dialog-title>Delete item?</h4>
<div mat-dialog-content>
  <p>This will delete the item immediately. It stays deleted even if you do not save your changes.</p>
</div>
<div mat-dialog-actions class="button-container align-right" style="margin-top: 20px;">
  <button mat-button class="button ghost" (click)="close(false)">Cancel</button>
  <button mat-button class="button" (click)="close(true)" cdkFocusInitial>Delete</button>
</div>
`
})
export class ConfirmDeleteDialogComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>) { }

  close(res: boolean) {
    this.dialogRef.close(res);
  }
}
