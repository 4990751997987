import { Injectable, Inject } from '@angular/core';
import { WorkBook, read, utils } from 'xlsx';
import { SCHEMA } from '../../shared/schema';
import { PARSE_EXCEL } from './parser.worker';
import { WebWorkerService } from 'ngx-web-worker';

@Injectable({
  providedIn: 'root'
})
export class ParserService {

  constructor(@Inject(WebWorkerService) private workerService: WebWorkerService) { }

  parse(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {

      const reader: FileReader = new FileReader();
      reader.onload = async(e: any) => {
        const bstr: string = e.target.result;

        const input = {
          data: bstr,
          host: window.location.host,
          path: window.location.pathname,
          protocol: window.location.protocol,
          worker: true
        };

        let workbook: WorkBook;
        try {
          workbook = await this.workerService.run(PARSE_EXCEL, input);
        } catch (err) {
          reject(err);
        }

        const sheet = workbook.Sheets.Template;
        console.log('loaded worksheet');

        if (!sheet) {
          reject('Excel file does not contain worksheet "Template"');
          return;
        }

        // Check general file integrity
        const errors = [];
        for (const def of SCHEMA) {
          const header = sheet[def.headerCell];
          if (!header) {
            errors.push(`Missing column '${def.name}' (header cell has to be ${def.headerCell})`);
          } else if (header.w !== def.name) { // w is text
            errors.push(`Header cell '${def.name}' should be in ${def.headerCell}, instead found '${header.w}'`);
          }
        }
        if (errors.length > 0) {
          reject(errors.join('; '));
          return;
        }

        const parsedXSLX = utils.sheet_to_json(sheet, { range: 3 }); // 3 to skip header rows

        resolve(parsedXSLX);
      };
      reader.readAsBinaryString(file);
    });
  }

}
