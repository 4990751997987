import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User, UserSettings, IFRS16Scope } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { AreDialogComponent, AreDialogData } from '../are-dialog/are-dialog.component';
import { fleet, monitoring } from 'src/app/shared/roles';


@Component({
  selector: 'pwo-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  @Input() user: User;
  @Output() save = new EventEmitter();

  get isAdmin() {
    return this.user.settings.isLocalAdmin || this.user.settings.isSystemAdmin;
  }

  roles: { title: string, value: UserSettings['role'] }[] = [
    { title: 'None', value: 'none' },
    { title: 'Local SCM', value: 'scm' },
    { title: 'Accountant', value: 'accountant' }
  ];
  monitoringRoles: { title: string, value: UserSettings['monitoring'] }[] = [
    { title: 'None', value: false },
    { title: 'Monitoring', value: true }
  ];

  currentUser: User;

  isFleet = fleet;
  isMonitoring = monitoring;

  constructor(private userService: UserService, private dialog: MatDialog) { }

  ngOnInit() {
    this.userService.user.subscribe(u => this.currentUser = u);
  }

  assignARE(scope: IFRS16Scope) {
    const dialogData: AreDialogData = {
      scope,
      editor: this.currentUser,
      assignedAREs: scope === 'fleet' ? this.user.settings.assignedAREs : this.user.settings.assignedAREsMonitoring,
      purpose: 'bdme'
    };
    const ref = this.dialog.open(AreDialogComponent, { data: dialogData });
    ref.afterClosed().subscribe(resp => {
      console.log('dialog closed', resp);
      if (!resp) { return; } // cancelled

      if (scope === 'fleet') {
        this.user.settings.assignedAREs = resp;
      } else {
        this.user.settings.assignedAREsMonitoring = resp;
      }
    });
  }

  onSave() {
    this.save.emit();
  }

}
