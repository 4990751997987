import { Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pwo-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss']
})
export class CountrySelectComponent implements OnInit {

  @Input() countries: string[];
  @Output() selectCountry = new EventEmitter();

  countryStr: string;
  filteredCountries: string[];

  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(private route: ActivatedRoute) { }

  onInput() {
    // this.filteredCountries = this.countryCtrl.valueChanges.pipe(
    //   map((val: string | null) => val && typeof val === 'string' ? this._filter(val) : this.countries.slice())
    // );
    if (this.countryStr && this.countryStr.length > 0) {
      this.filteredCountries = this._filter(this.countryStr);
    } else {
      this.filteredCountries = this.countries;
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.countryStr = params.country;
    });

  }

  onSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectCountry.next(event.option.value);
  }

  private _filter(value: string): string[] {
    // console.log(value);
    const filterValue = value.toLowerCase();

    return this.countries.filter(c => {
      return c.toLowerCase().indexOf(filterValue) === 0;
    });
  }

}
