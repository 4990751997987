import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';
import * as Roles from 'src/app/shared/roles';
import { UserService } from 'src/app/shared/services/user.service';
import { MenuService } from 'src/app/static/services/menu.service';

@Component({
  selector: 'pwo-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  user: User;
  isAdmin = false;
  roles = Roles;
  areInput: string = '';
  userList: any[];
  adminContacts: string[];
  selectedAuthRole: string = 'Local SCM';
  authRoles: string[] = ['Local SCM', 'Accountant'];

  constructor(private userService: UserService, private menuService: MenuService) { }

  ngOnInit() {
    this.userService.user.subscribe(user => {
      this.user = user;
      this.isAdmin = user.settings.isLocalAdmin || user.settings.isSystemAdmin;
    });
    this.getUserList();
  }

  changeRole() {
    this.user.settings.role = this.user.settings.role === 'scm' ? 'accountant' : 'scm';
    this.userService.setCurrentUser(this.user);
  }

  close() {
    this.menuService.close();
  }

  searchAdmin() {
    this.adminContacts = [];
    this.userList.forEach(user => {
      Object.keys(user.settings.assignedAREs).forEach(country => {
        // SysAdmins and Service Desk People should not be added to list
        if (user.settings.assignedAREs[country].includes(this.areInput) && user.settings.isLocalAdmin && !user.settings.isSystemAdmin && 
              user.gid !== 'Z002U47A' && user.gid !== 'Z003PXUZ' && user.gid !== 'Z0042B9B') {
          this.adminContacts.push(user.mail);
        }
      });
    });
    console.log(this.adminContacts);
  }

  async getUserList() {
    this.userService.fetchAllUsers().then(result => {
      this.userList = result;
    });
  }

}
