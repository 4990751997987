// import { NgModule, ModuleWithProviders } from '@angular/core';

// import { AuthGmsClientConfig, AuthGmsClientConfigService } from './models/auth-gms-client.config';
// import { AuthService } from './services/auth-gms-client.service';

// import { AuthGmsClientCallbackComponent } from './components/auth-gms-client-callback.component';

// @NgModule({
//   declarations: [ AuthGmsClientCallbackComponent ],
//   exports: [ AuthGmsClientCallbackComponent ]
// })
// export class AuthGmsAngularClientModule {

//   static forRoot(config: AuthGmsClientConfig): ModuleWithProviders<AuthGmsAngularClientModule> {
//     return {
//       ngModule: AuthGmsAngularClientModule,
//       providers: [
//         AuthService,
//         {
//           provide: AuthService,
//           useValue: config
//         }
//       ]
//     }
//   }
// }



import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { TokenInjector } from './services/token.injector';
//import { TokenInjector } from './services/token-injector.interceptor';
//import { AuthGmsRoutingModule } from './auth-routing.module';
//import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

@NgModule({
    declarations: [],
    exports: [AuthModule],
    imports: [
        //AuthGmsRoutingModule,
        AuthModule.forRoot({
            config: environment.authentication
        }),
        SharedModule
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true }]
})
export class AuthenticationModule {}
