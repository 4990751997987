import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view/view.component';
import { ViewTableComponent } from './view/view-table/view-table.component';
import { SharedModule } from '../../shared/shared.module';
import { PwoFilterCloudModule } from 'projects/pwo-filter-cloud/src/public_api';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';

@NgModule({
    declarations: [
        ViewComponent,
        ViewTableComponent,
        ExportDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PwoFilterCloudModule
    ]
})
export class BrowseModule { }
