import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pwo-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() pages: Page[] | number[];
  @Input() summary: Summary;

  @Output() selected = new EventEmitter();

  @Input() limit = 100;
  @Output() limitChange = new EventEmitter();

  limits = [ 50 ];

  constructor() { }

  ngOnInit() {
  }

  changePage(page: Page) {
    this.selected.emit(page.number || page);
  }

  selectLimit(lim) {
    this.limit = lim;
    this.limitChange.emit(lim);
  }

}

export interface Page {
  number: number;
  done: boolean;
}

export interface Summary {
  page: number;
  start: number;
  end: number;
  total: number;
}
