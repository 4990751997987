import { Directive, ContentChild, OnInit, OnDestroy } from '@angular/core';
import { PwoFilterComponent } from './pwo-filter/pwo-filter.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Directive({
// tslint:disable-next-line: directive-selector
  selector: '[pwoFilterCloudAnchor]',
  // host: {
  //   'aria-haspopup': 'true',
  //   '[attr.aria-expanded]': 'menuOpen || null',
  //   '(mousedown)': '_handleMousedown($event)',
  //   '(keydown)': '_handleKeydown($event)',
  //   '(click)': '_handleClick($event)',
  // },
  exportAs: 'matMenuTrigger'
})
export class PwoFilterAnchorDirective extends MatMenuTrigger implements OnInit, OnDestroy {

  @ContentChild(PwoFilterComponent, { static: false }) filterComponent: PwoFilterComponent;

  ngOnInit() {
    if (!this.filterComponent) {
      throw new Error('pwoFilterCloudAnchor must have PwoFilterComponent (<pwo-filter-cloud>) as a child!');
    }

    this.filterComponent.isAnchored = true;

    this.menu = this.filterComponent.menuRef;

    this.filterComponent.toggle.subscribe((state: boolean) => {
      if (this.menuOpen !== state) {
        if (state) { this.openMenu(); } else { this.closeMenu(); }
      }
    });
  }

  ngOnDestroy() {
    this.filterComponent.toggle.unsubscribe();
  }

}
