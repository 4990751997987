import { User } from './models/user.model';

export type Role = (user: User) => boolean;

export function scm(user: User) {
    return user && user.settings.role === 'scm';
}
export function accountant(user: User) {
    return user && user.settings.role === 'accountant';
}
export function fleet(user: User) {
    return scm(user) || accountant(user);
}

export function monitoring(user: User) {
    return user && user.settings.monitoring;
}

export function admin(user: User) {
    return user && (user.settings.isLocalAdmin || systemAdmin(user));
}

export function systemAdmin(user: User) {
    return user && user.settings.isSystemAdmin;
}
