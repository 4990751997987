import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DateHelperService } from '../services/date-helper.service';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe, private dateHelper: DateHelperService) {
  }

  transform(value: any, type: string): any {
    switch (type) {
      case 'date': return this.date(value);
      case 'month': return this.dateHelper.formatMonth(value);
      case 'currency': return this.currencyPipe.transform(value, 'EUR');

      default: return value;
    }
  }

  date(value: string) {
    if (!value) { return ''; }

    return new Date(value).toLocaleDateString();

    // return moment(value)
    //   .locale(this.translateService.currentLang)
    //   .utc()
    //   .format('L');
  }
}
