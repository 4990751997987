import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Filter } from '../pwo-filters';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import * as F from '../pwo-filters';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'pwo-filter-cloud, [pwoFilterCloud]',
  templateUrl: './pwo-filter.component.html',
  styleUrls: ['./pwo-filter.component.scss']
})
export class PwoFilterComponent {

  @ViewChild(MatMenuTrigger, { static: false }) triggerMenu: MatMenuTrigger;

  @Input() align: string;
  @Input() filter: Filter;
  @Input() filterService: Filter;

  @Output() toggle = new EventEmitter();

  @ViewChild('filterMenu', { static: false }) menuRef: MatMenu;

  visible = false;

  filterTypes = F;

  isDisabled = false;
  isAnchored = false;
  isHeaderFilter: boolean;

  hasStyles = false;

  get xPosition() {
    return this.align === 'right' ? 'before' : 'after';
  }

  constructor(element: ElementRef) {
    // Detect if used as a header filter in MatTable
    const p = element.nativeElement.parentElement;
    this.isHeaderFilter = p && (p.tagName === 'MAT-HEADER-CELL' || p.attributes.getNamedItem('mat-header-cell'));

    this.hasStyles = element.nativeElement.hasAttribute('pwo');
  }

  is(filter: Filter, filterType: any) {
    return filter instanceof filterType;
  }

  open() {
    this.toggle.next(true);
  }

  close() {
    if (this.isAnchored) {
      this.toggle.next(false);
    } else {
    }
    if (this.triggerMenu) {
      this.triggerMenu.closeMenu();
    }
  }

}
