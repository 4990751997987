import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { User, IFRS16Scope } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { AreDialogComponent, AreDialogData } from '../user-management/are-dialog/are-dialog.component';

@Component({
  selector: 'pwo-bdme-dialog',
  templateUrl: './bdme-dialog.component.html',
  styleUrls: ['./bdme-dialog.component.scss']
})
export class BdmeDialogComponent implements OnInit {

  isLoading = true;
  data: { month: string, data: any }[];
  links: { file: string, url: string }[];
  linksDelta: { file: string, url: string, size: number, lastModified: Date }[];
  month: Date;
  user: User;
  exportType: 'all' | 'filter' = 'all';
  areFilter: string[] = [];

  constructor(public dialogRef: MatDialogRef<BdmeDialogComponent>, 
              private api: ApiService, 
              private notif: NotificationService,
              private userService: UserService,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.month = this.api.month;
    this.loadData();
    this.userService.user.subscribe(user => {
      if (user && user.settings) {
        this.user = user;
      }
    });
  }

  onGenerateClicked() {
    this.exportType = 'all';
    this.generate();
  }

  onFilterSelected() {
    this.exportType = 'filter';
    this.generate();
  }

  async generate() {
    this.isLoading = true;
    try {
      let areList = [];
      let areString = '';
      if (this.exportType === 'all') {
        const userSettings = await this.api.getAREList();
        userSettings.forEach(set => areList.push(set.ARE));
        areString = areList.join(',');
        await this.api.generateBDME(this.month, areString);
        this.loadData();
      } else {
        const scope: IFRS16Scope = 'fleet';
        const dialogData: AreDialogData = {
          scope,
          editor: this.user,
          assignedAREs: this.user.settings.assignedAREs,
          purpose: 'bdme'
        };
        const ref = this.dialog.open(AreDialogComponent, { data: dialogData });
        ref.afterClosed().subscribe(async resp => {
          if (resp) {
            Object.keys(resp).forEach(country => {
              areList = areList.concat(resp[country]);
            });
            areString = areList.join(',');
            console.log('ARE STRING: ', areString)
            const test = await this.api.generateBDME(this.month, areString);
            console.log('TEST: ', test)
            this.loadData();
          } else {
            this.isLoading = false;
          }
        });
      }
    } catch (err) {
      this.notif.error(err);
      this.isLoading = true;
    }
  }

  selectAREs() {
    const dialogData: AreDialogData = {
      scope: 'fleet',
      editor: this.user,
      assignedAREs: this.user.settings.assignedAREs,
      purpose: 'bdme'
    }
    const ref = this.dialog.open(AreDialogComponent, { data: dialogData });
    ref.afterClosed().subscribe(resp => {
      Object.keys(resp).forEach(country => {
        this.areFilter = this.areFilter.concat(resp[country]);
      });
      this.generate();
    });
  }

  async loadData(month = this.month) {
    this.isLoading = true;
    try {
      this.links = await this.api.getBDMELinks(month);
    } catch (err) {
      this.notif.error(err);
    }
    this.isLoading = false;
  }

  close() {
    this.dialogRef.close();
  }

  fileSize(val: number) {
    const suffixes = ['B', 'KB', 'MB', 'GB' ];
    let index = 0;
    while (val > 1024 && index < suffixes.length) {
      val /= 1024;
      index++;
    }
    return Math.round(10 * val) / 10 + ' ' + suffixes[index];
  }

}
