import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pwo-language-menu-subcomponents',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent implements OnInit {

  public languageSelected = 'en';
  public languages = ['en', 'de', 'fr'];

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    const lang = localStorage.getItem('language');
    this.setLanguage(lang);
  }

  changeLanguage(e) {
    const lang = e.value;
    this.setLanguage(lang);
    localStorage.setItem('language', lang);
  }

  setLanguage(lang: string) {
    if (lang !== null) {
      this.languageSelected = lang;
      this.translate.use(lang);
    }
  }

}
