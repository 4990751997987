import 'rxjs/Rx';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { AWSHttpClient } from '../../../ngx-api-gateway-client/aws-http.client';
import { map } from 'rxjs/operators';

@Injectable()
export class AwsServerlessApiService {

  private apiUrl;
  private apiUrlMonitoring;

  constructor(private awsHttp: AWSHttpClient) {
    this.apiUrl = environment.webapi;
    this.apiUrlMonitoring = environment.webapiMonitoring;
  }

  public fleet() {
    return {
      get: (src: string) => this.get(this.apiUrl + src),
      postDynamo: (src: string, body: any) => this.postDynamo(this.apiUrl + src, body),
      post: (src: string, body: any) => this.post(this.apiUrl + src, body),
      put: (src: string, body: any) => this.put(this.apiUrl + src, body),
      delete: (src: string, params: any) => this.delete(this.apiUrl + src, params),
      patch: (src: string, params: any) => this.patch(this.apiUrl + src, params),
    };
  }

  public monitoring() {
    return {
      get: (src: string) => this.get(this.apiUrlMonitoring + src),
      postDynamo: (src: string, body: any) => this.postDynamo(this.apiUrlMonitoring + src, body),
      post: (src: string, body: any) => this.post(this.apiUrlMonitoring + src, body),
      put: (src: string, body: any) => this.put(this.apiUrlMonitoring + src, body),
      delete: (src: string, params: any) => this.delete(this.apiUrlMonitoring + src, params),
      patch: (src: string, params: any) => this.patch(this.apiUrlMonitoring + src, params),
    };
  }

  private get(url: string) {
    return this.awsHttp.get(url,
      { headers: this.getHeaders(), responseType: 'json' }).pipe(map((response: any) => response));
  }

  private postDynamo(url: string, body: any) {
    return this.awsHttp.post(url, body,
      { headers: this.getHeaders(), responseType: 'json' }).map((response: any) => JSON.parse(response.body));
  }

  private post(url: string, body: any) {
    return this.awsHttp.post(url, body,
      { headers: this.getHeaders(), responseType: 'json' }).pipe(map((response: any) => response));
  }

  private put(url: string, body: any) {
    return this.awsHttp.put(url, body,
      { headers: this.getHeaders(), responseType: 'json' }).pipe(map((response: any) => response));
  }

  private delete(url: string, params: any) {
    return this.awsHttp.delete(url,
      { headers: this.getHeaders(), params: params, responseType: 'json' }).map((response: any) => response);
  }

  private patch(url: string, params: any) {
    return this.awsHttp.patch(url,
      { headers: this.getHeaders(), params: params, responseType: 'json' }).map((response: any) => response);
  }

  private getHeaders() {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('Access-Control-Allow-Headers', 'X-Requested-With,Content-type');
    headers.append('Access-Control-Allow-Methods', 'PUT,POST,GET,DELETE,OPTIONS,PATCH');
    headers.append('Content-Type', 'application/json; charset=utf-8');
    return headers;
  }

  // TODO: Testing own aws signature to remove aws-sdk dependency
  // just call this in organisation-chart.services.ts -> get ORgData for tests
  /*
  public getWithOwnAWSv4Signature() {

    this.awsSignatureService.host = 'smigqs8h1m.execute-api.eu-west-1.amazonaws.com';

    this.awsSignatureService.sessionToken = this.authService.cred.sessionToken;
    this.awsSignatureService.accessKeyId = this.authService.cred.accessKeyId;
    this.awsSignatureService.secretAccessKey = this.authService.cred.secretAccessKey;
    this.awsSignatureService.awsRegion = 'eu-central-1';
    this.awsSignatureService.awsService = 'execute-api';

    let query = [
      { key: 'ORGCODE', value: 'SIEMENS' }
    ];

    return this.awsSignatureService.GetAWS('/prod/orgcode', query);
  }
*/


}
