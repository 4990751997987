import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'item'
})
export class ItemPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === 0) {
      return 'no item';
    } else if (value === 1) {
      return 'one item';
    } else {
      return value + ' items';
    }
  }

}
