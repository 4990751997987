import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pwo-are-picker',
  templateUrl: './are-picker.component.html',
  styleUrls: ['./are-picker.component.scss']
})
export class ArePickerComponent implements OnInit {

  @Input() ares: string[];
  @Output() selectAre = new EventEmitter();

  selectedAre: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selectedAre = params.are;
    });
  }

  onSelectionClose(opened: boolean) {
    if (!opened) {
        this.selectAre.next(this.selectedAre);
    }
}

}
