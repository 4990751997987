import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from './notification.service';

import * as moment from 'moment';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';
import { SCHEMA } from '../schema';
import { e } from '../error-to-string';
import { FilterRecord } from 'pwo-filter-cloud/public_api';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  flags = [
    'CR',
    'DE',
    'CC',
    'CP',
    'CO',
    'CE',
    'IF',
    'RV',
    'CS',
    'IN',
    'CU',
    'FR',
    'FU',
    'PF',
    'LI',
    'ST'
  ];

  constructor(private api: ApiService, private notif: NotificationService) { }


  async exportChanges(filters: FilterRecord[]): Promise<string> {
    const workbook = new Excel.Workbook();
    const filename = 'IFRS16_Fleet_Delta_' + this.api.formatMonth() + '.xlsx';

    const style = { font: { color: { argb: 'FF000000' } } };
    const header = [
      // { header: 'Country', key: 'country', width: 25, style },
      { header: 'ARE', key: 'ARE', width: 5, style },
      { header: 'CostCenter', key: 'cost_center', width: 11, style },
      { header: 'vin_number', key: 'vin_number', width: 25, style },
      { header: 'ifa_number', key: 'ifa_number', width: 11, style },
      { header: 'vert_nummer_lessor', key: 'vert_nummer_lessor', width: 19, style },
      { header: 'leasing_supplier', key: 'leasing_supplier', width: 35, style },
      { header: 'residual_value', key: 'residual_value', width: 13, style },
      { header: 'Contract_Start_Date', key: 'contract_start', width: 18, style },
      { header: 'Contract_End_Date', key: 'contract_end', width: 18, style },
      { header: 'Order_date_of_the_vehicle', key: 'order_date', width: 25, style },
      { header: 'interest_rate', key: 'interest_rate', width: 18, style },
      { header: 'Funding_Finance_Lease', key: 'funding_finance_lease', width: 21, style },
      { header: 'service_rate', key: 'service_rate', width: 11, style },
      { header: 'Currency', key: 'currency', width: 8, style },
      { header: 'Frequency', key: 'frequency', width: 9, style },
      { header: 'Frequency_Unit', key: 'frequency_unit', width: 14, style },
      { header: 'Payment_Form', key: 'payment_form', width: 14, style },
      { header: 'Intercompany (Sub-Group) Lease', key: 'intercompany_lease', width: 21, style },
      { header: 'Sublease Type', key: 'sublease_type', width: 16, style },
      { header: 'Lease Incentive', key: 'lease_incentives', width: 16, style },
      { header: 'period', key: 'period', width: 6, style },
      { header: 'FY', key: 'fiscal_year', width: 5, style },
      // { header: 'contract_expired', key: 'contract_expired', width: 15, style }
    ];

    this.flags.forEach(f => {
      const key = 'ch_' + f;
      header.push({ header: key, key, width: 8, style });
    });

    const sheet = workbook.addWorksheet('Fleet_Data');
    sheet.columns = header;

    try {
      const data = await this.api.exportProd('changes', filters);
      console.log(data);

      data.forEach(el => {
        this.flags.forEach(f => {
          const key = 'ch_' + f;
          el[key] = el.flags && el.flags.includes(f);
        });

        el.contract_end = el.contract_end && new Date(el.contract_end);
        el.contract_start = el.contract_start && new Date(el.contract_start);
        el.order_date = el.order_date && new Date(el.order_date);

        // el.contract_expired = el.contract_end < month;
        const month = new Date(el.month);
        el.period = month.getMonth() + 1;
        el.fiscal_year = month.getFullYear();
      });
      sheet.addRows(data);

      sheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF4472C4' }
      };
      sheet.getRow(1).font = { color: { argb: 'FFFFFFFF' } };

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), filename);
      return 'Excel export successfully generated';
    } catch (err) {
      console.log('Error writing excel export', err);
      return 'Error writing excel export: ' + e(err);
    }
  }


  /**
   * This exports data matching the specified filters in the IFRS16 Fleet template format.
   * This way, it can be uploaded to the DC again.
   *
   * @param filters Array of FilterRecords to limit the data
   */
  async exportProd(filters: FilterRecord[]): Promise<string> {
    const filename = 'IFRS16_' + this.api.formatMonth() + '.xlsx';

    const template = await this.getTemplate();

    // Export using Sheet.js
    // const workbook = XLSX.read(data, { type: 'array' });
    // const sheet = workbook.Sheets.Template;

    // this.api.exportProd().then(data => {
    //   console.log(data);

    //   data.forEach(el => {
    //     el.contract_end = new Date(el.contract_end);
    //     el.contract_start = new Date(el.contract_start);
    //     el.order_date = new Date(el.order_date);
    //   });

    //   data = data.slice(0, 10);

    //   XLSX.utils.sheet_add_json(sheet, data, { skipHeader: true, origin: "B6", header: SCHEMA.map(el => el.key) });

    //   console.log(sheet);
    //   XLSX.writeFile(workbook, filename);

    //   resolve('done');
    // });


    // Export using Excel.js
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(template);
    const sheet = workbook.worksheets.find(el => el.name === 'Template');

    // Set header cells
    SCHEMA.forEach(el => {
      const col = sheet.getColumn(el.headerCell.replace(/\d+/g, ''));
      col.key = el.key;
    });

    // Add submitted by and timestamp
    sheet.getColumn('V').key = 'submitted_by';
    sheet.getColumn('W').key = 'timestamp';

    // Add data
    const data = await this.api.exportProd('data', filters);
    const dateKeys = ['contract_end', 'contract_start', 'order_date', 'timestamp'];
    data.forEach(el => {
      dateKeys.forEach(key => {
        el[key] = el[key] && new Date(el[key]);
      });
    });
    sheet.addRows(data);

    // Remove example row (must be done after adding data because last row can't be removed)
    sheet.spliceRows(6, 1);

    // Write file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), filename);

    return 'Excel export successfully generated';
  }

  private getTemplate() {
    return new Promise((resolve, reject) => {
      const url = '/assets/IFRS16 Reporting Template Fleet_V3.xlsx';

      const req = new XMLHttpRequest();
      req.open('GET', url, true);
      req.responseType = 'arraybuffer';

      req.onload = () => {
        const data = new Uint8Array(req.response);
        resolve(data);
      };

      req.onerror = err => {
        reject(err);
      };

      req.send();
    });
  }

  /**
   * This saves the specified dataset to an Excel file
   *
   * @param items Array of Items which will be added to the Excel file
   */
  exportDataset(items) {
    const workbook = new Excel.Workbook();
    const filename = 'dump_' + Date.now() + '.xlsx';

    const header = SCHEMA.map(el => ({
      header: el.name,
      key: el.key
    }));

    const sheet = workbook.addWorksheet();
    sheet.columns = header;

    sheet.addRows(items);

    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer]), filename);
    }).catch(err => {
      console.log('Error writing excel export', err);
    });
  }

}
