import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ValueFilter, Filter, FilterRecord } from 'projects/pwo-filter-cloud/src/public_api';

@Component({
  selector: 'pwo-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {

  month: Date;
  type: 'month' | 'view' = 'month';

  get canExportFilter() {
    return this.data.count <= 50000;
  }

  constructor(public dialogRef: MatDialogRef<ExportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private api: ApiService) {
    console.log('dialog data', data);
  }

  ngOnInit() {
    this.month = this.data.selectedMonth;
  }

  close(res: boolean) {
    let filters: FilterRecord[];
    if (this.type === 'month') {
      const vf = new ValueFilter('month');
      vf.value = this.month.toISOString();
      filters = [ vf.toFilterRecord() ];
    } else {
      filters = (this.data.filters as Filter[]).map(f => f.toFilterRecord());
    }
    this.dialogRef.close([res, filters]);
  }

}
