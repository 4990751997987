import { Component, OnInit } from '@angular/core';
import { IScdUser } from 'pwo-scd';
import { User, getDefaultUserSettings } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { e } from 'src/app/shared/error-to-string';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pwo-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  scdUser: IScdUser;
  // gid: string;
  user: User;

  mode: 'create' | 'edit';

  constructor(private userService: UserService, private notif: NotificationService, private route: ActivatedRoute) { }

  ngOnInit() {
    // this.route.params.subscribe(params => {
    //   this.gid = params.gid;
    //   this.load()
    // });
    const param = location.search.replace('?', '').split('&').find(part => part.startsWith('gid'));
    const gid = param && param.split('=')[1];
    if (gid && gid.length) {
      this.scdUser = { gid } as IScdUser; this.userSelected();
    }
    console.log('GID: ', gid);
  }

  userSelected() {
    this.userService.fetchUser(this.scdUser.gid).then(user => {
      if (user) {
        this.mode = 'edit';
        this.user = user;
      } else {
        this.mode = 'create';
        this.user = {
          gid: this.scdUser.gid,
          firstName: this.scdUser.firstName,
          lastName: this.scdUser.surName,
          mail: this.scdUser.mail,
          settings: getDefaultUserSettings()
        };
      }
    }).catch(err => {
      this.notif.error('Failed accessing user database: ' + e(err));
    });
  }

  saveUser() {
    console.log('saving', this.user);
    this.userService.putUser(this.mode, this.user).then(res => {
      console.log(res);
      this.notif.info('Saved user');
    }).catch(err => {
      console.log(err);
      this.notif.error('Error saving user: ' + e(err));
    });
  }

}
