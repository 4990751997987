export const FLAG_TOOLTIPS = {
    CR: 'Item was created',
    DE: 'Item was deleted',

    RE: 'Item gets recreated due to changing the cost center with the new cost center having a different z-company code',

    AR: 'ARE was changed',
    CC: 'Cost Center was changed',
    CP: 'Cost Center and Profit Center were changed',
    CO: 'Funding / Finance lease was changed',
    CE: 'Contract end date was changed',

    IF: 'IFA number was changed',
    RV: 'Residual value was changed',
    CS: 'Contract start date was changed',
    IN: 'Interest rate was changed',
    CU: 'Currency was changed',
    FR: 'Frequency was changed',
    FU: 'Frequency unit was changed',
    PF: 'Payment form was changed',

    LI: 'Lease Incentive was changed',
    ST: 'Sublease Type was changed'
};
