import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { HttpRequest, HttpHeaders, HttpClient } from '@angular/common/http';
import { UploadValidationTableService } from '../services/upload-validation-table.service';
import { e } from 'src/app/shared/error-to-string';

@Component({
  selector: 'pwo-upload-validation-table',
  templateUrl: './upload-validation-table.component.html',
  styleUrls: ['./upload-validation-table.component.scss']
})
export class UploadValidationTableComponent implements OnInit {

  isLoading = false;
  isUploading = false;
  status: {
    id: number;
    range_key: string; // filename
    data: any;
  }[];

  constructor(public dialogRef: MatDialogRef<UploadValidationTableComponent>,
    private notif: NotificationService, private service: UploadValidationTableService,
    private api: ApiService) { }

  ngOnInit() {
    this.loadStatus();
  }

  async loadStatus() {
    this.isLoading = true;

    this.isUploading = await this.service.isUploading();
    if (this.isUploading) {
      this.registerCallback();
    }
    this.isLoading = this.isUploading;
  }

  onSelected(files) {
    console.log(files);
    if (files.length > 0) {
      const file = files[0];
      // if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // if (file.type === 'text/plain') {
      if (file.type === 'application/vnd.ms-excel') {
        this.upload(file);
      } else {
        this.notif.error('File is not valid, choose export as "CSV (Comma delimited)" in Excel!');
      }
    } else {
      this.notif.error('Got no file');
    }
  }

  async upload(file: File) {
    try {
      this.isLoading = true;
      this.isUploading = true;

      await this.service.setUploadingStatus();

      this.registerCallback();

      // Upload file to S3, this triggers the Lambda and finally calls the callback
      await this.service.upload(file);
    } catch (err) {
      console.log(err);
      this.notif.error(e(err));
    }
  }

  registerCallback() {
    // Register callback
    this.api.waitFor('validation-table', { maxTries: 30, interval: 30 }).then(async (ev:  any) => {
      console.log(ev);
      this.notif.info(ev, { persistent: true });
      await this.service.clearStatus();
      this.close();
    }).catch(err => {
      this.notif.error('Error: ' + e(err), { persistent: true });
      this.isLoading = false;
    });
  }

  close() {
    this.dialogRef.close();
  }

}
