import { FilterValue, FilterRecord } from './pwo-filter.types';

/**
 * Generic filter, can't be used to filter a column!
 */
export abstract class Filter {
  key: string;
  isApplied = false;

  constructor(key: string) {
    this.key = key;
  }

  abstract isActive(): boolean;

  abstract toFilterRecord(): FilterRecord;
}


/**
 * Use this filter to filter for a specific value in a column.
 */
export class ValueFilter<T> extends Filter {
  value: T;
  transform = val => val;

  isActive() {
    return this.value !== undefined && this.value !== null;
  }

  toFilterRecord() {
    return {
      key: this.key,
      type: 'list',
      values: [ this.value ]
    };
  }
}

/**
 * Use this filter to filter for multiple possible values in a column.
 */
export class MultiValueFilter<T> extends Filter {
  /**
   * An array with the titles of the selected filter values.
   */
  selected: Array<T>;

  /**
   * Set this to true to set the filter even if the selected array is empty.
   */
  forceFilter = false;

  /**
   * If you specify a data delimiter, the Filter will treat the column as a list.
   */
  delimiter = undefined;

  flattened?: Array<T>;

  isActive() {
    return this.selected && (this.forceFilter || this.selected.length > 0);
  }

  toFilterRecord() {
    return {
      key: this.key,
      type: 'list',
      values: this.selected
    };
  }
}

/**
 * Use this filter to filter numbers that are in the specified range.
 */
export class RangeFilter extends Filter {
  min: number;
  max: number;

  isActive() {
    return (this.min !== undefined && this.min !== null) || (this.max !== undefined && this.max !== null);
  }

  toFilterRecord() {
    return {
      key: this.key,
      type: 'range',
      min: this.min,
      max: this.max
    };
  }
}

/**
 * Use this filter to filter dates. You can specify a start date, an end date or both.
 */
export class DateFilter extends Filter {
  start: Date;
  end: Date;

  isActive() {
    return (this.start !== undefined && this.start !== null) || (this.end !== undefined && this.end !== null);
  }

  toFilterRecord() {
    return {
      key: this.key,
      type: 'range',
      min: this.start,
      max: this.end
    };
  }
}

/**
 * Use this filter to search in a column.
 */
export class SearchFilter extends Filter {
  term = '';

  isActive() {
    return this.term.length > 0;
  }

  toFilterRecord() {
    return {
      key: this.key,
      type: 'search',
      term: this.term
    };
  }
}
