import { EventEmitter } from '@angular/core';
import { AppliedFilters, FilterRecord, FilterValue } from './pwo-filter.types';
import { Filter } from './pwo-filters';

type getItemsFunc = (records: FilterRecord[]) => Promise<any[]>;
type getFilterValuesFunc = (filter: FilterRecord, records: FilterRecord[]) => Promise<{
  values?: any[];
  min?: any;
  max?: any;
}>;
interface Api {
  getItems: getItemsFunc;
  getFilterValues: getFilterValuesFunc;
}

export class PwoFilterCloudService {

  public data = new EventEmitter();
  public apply = new EventEmitter();

  private appliedFilters: AppliedFilters = {};
  private api: Api;

  // private _search = '';
  // get search(): string {
  //   return this._search;
  // }
  // set search(search: string) {
  //   this._search = search;
  //   this.triggerFilter();
  // }

  constructor(getItems: getItemsFunc, getFilterValues: getFilterValuesFunc, initialLoad = true) {
    this.api = { getItems, getFilterValues };
    if (initialLoad) { this.triggerFilter(); }
  }

  applyFilter(filter: Filter, skipTrigger = false) {
    console.log('apply', filter);

    if (filter.isActive()) {
      // Set filter
      filter.isApplied = true;
      this.appliedFilters[filter.key] = filter;
    } else {
      // Delete filter
      filter.isApplied = false;
      delete this.appliedFilters[filter.key];
    }

    this.setFilterActiveClass(filter);
    this.apply.emit(filter);

    if (!skipTrigger) {
      this.triggerFilter();
    }
  }

  getFilterValues(filter: Filter): Promise<any> {
    // Get records excluding this one
    const records = this.getRecords().filter(el => el.key !== filter.key);
    return this.api.getFilterValues(filter.toFilterRecord(), records);
  }

  private getRecords() {
    return Object.values(this.appliedFilters).map(f => f.toFilterRecord());
  }

  private setFilterActiveClass(filter) {
    const el = document.querySelector('.mat-column-' + filter.key);
    if (el) {
      if (filter.isActive()) {
        el.classList.add('pwo-filter-active');
      } else {
        el.classList.remove('pwo-filter-active');
      }
    }

  }

  isSet(filter) {
    return this.appliedFilters[filter.key] ? true : false;
  }

  hasFilterSet() {
    return Object.values(this.appliedFilters).length > 0;
  }

  clearAllFilters() {
    Object.values(this.appliedFilters).forEach((filter: Filter) => filter.isApplied = false);
    this.appliedFilters = {};
    this.triggerFilter();

    // Remove all filter active classes
    const cols = document.querySelectorAll('.pwo-filter-active');
    cols.forEach(col => col.classList.remove('pwo-filter-active'));
  }

  /**
   * If you want to set multiple filters at once, call applyFilter
   * with skipTrigger set to true (applyFilter([...], true)) and
   * call this function afterwards.
   */
  triggerFilter() {
    const records = this.getRecords();
    this.api.getItems(records).then(values => {
      this.data.emit(values);
    });
  }

}
