import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDataService } from './shared/services/user-data.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { IEDialogComponent } from './shared/components/ie-dialog.component';
import { ApiService } from './services/api.service';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'pwo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IFRS16 Data Collector';
  isDeploying = false;

  constructor(
    public translate: TranslateService,
    private userData: UserDataService,
    public dialog: MatDialog,
    private api: ApiService,
    private userService: UserService
  ) {

    this.userService.user.subscribe((currentuser) => {
      if (currentuser) {
        this.api.storage('deployment', 'status').getAll().then(res => {
          if (res && res.length > 0) {
            this.isDeploying = true;
          }
        });
      }
    });

    if (this.detectIE() !== false) {
      this.dialog.open(IEDialogComponent, { disableClose: true });
    }

    window.addEventListener('beforeunload', (ev) => {
      console.log('before unload', ev, (window as any).dirty);
      if ((window as any).dirty) {
        ev.returnValue = true;
        return 'onbeforeunload';
      } else {
        return undefined;
      }
    });

    translate.setDefaultLang('en');
    const lang = localStorage.getItem('language');
    if (lang !== null) {
      translate.use(lang);
      this.userData.setCurrentLanguage(lang);
    } else { translate.use('en'); }

    // if (auth.user) {
    //   this.user.loadUserSettings(auth.user.gid).then(() => {
    //     console.log('authUser: ', authUser);
    //     if (!authUser) {
    //       this.router.navigateByUrl('/browse');
    //     }
    //     this.user._user = authUser;
    //     this.user.setCurrentUser(authUser);
    //   });
    // } else {
    //   // Load user settings and redirect after initial login
    //   this.auth.authUserInit.subscribe((redirectPath) => {
    //     if (redirectPath) {
    //       this.user.loadUserSettings(this.auth.user.gid).then(() => {
    //         this.router.navigateByUrl(redirectPath);
    //       });
    //     }
    //   });

    //   auth.login();
    // }


    // if ((auth && auth.user) || environment.useDummyUser) {
    //   this.user.authenticatedUser().subscribe((authUser) => {
    //       console.log('authUser: ', authUser);
    //       if (!authUser) {
    //         this.router.navigateByUrl('/browse');
    //       }
    //       this.user._user = authUser;
    //       this.user.setCurrentUser(authUser);
    //   });
    // }

    // this.auth.authUserInit.subscribe((redirectPath) => {
    //   if (redirectPath) {
    //     this.user.loadUserSettings(this.auth.user.gid).then(() => {
    //       this.router.navigateByUrl(redirectPath);
    //     });
    //   }
    // });

  }

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  detectIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

}
