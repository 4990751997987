import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TooltipDirective } from './directive/tooltip.directive';
import { BarRatingModule } from 'ngx-bar-rating';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalizedDatePipe } from './pipes/date.pipe';
import { LoggerService } from './services/logger.service';
import { ConsoleLoggerService } from './services/console-logger.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PwoFormsModule } from 'pwo-forms';
import { PwoScdModule } from 'pwo-scd';
import { AwsServerlessApiService } from './services/aws-serverless-api.service';
import { ToggleComponent } from './components/toggle/toggle.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ValidationFilterComponent } from './components/validation-filter/validation-filter.component';
import { UploadPeriodMessageComponent } from './components/upload-period-message/upload-period-message.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { FormatPipe } from './pipes/format.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AreListComponent } from './components/are-list/are-list.component';
import { AppRoutingModule } from '../app-routing.module';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { DisplayColumnsComponent } from './components/display-columns/display-columns.component';
import { FilePickerComponent } from '../fleet/upload/file-picker/file-picker.component';
import { ArePickerComponent } from './components/are-picker/are-picker.component';

@NgModule({
    imports: [
        AppRoutingModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatAutocompleteModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMenuModule,
        MatExpansionModule,
        MatStepperModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatSlideToggleModule,
        MatSortModule,
        MatPaginatorModule,
        MatCardModule,
        MatChipsModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatListModule,
        BarRatingModule,
        TranslateModule.forChild(),
        NgxChartsModule,
        PwoFormsModule,
        PwoScdModule,
        HttpClientModule
    ],
    declarations: [
        TooltipDirective,
        LocalizedDatePipe,
        ToggleComponent,
        NotificationComponent,
        ValidationFilterComponent,
        UploadPeriodMessageComponent,
        CountrySelectComponent,
        FormatPipe,
        ProgressBarComponent,
        AreListComponent,
        PaginatorComponent,
        MonthPickerComponent,
        DisplayColumnsComponent,
        FilePickerComponent,
        ArePickerComponent
    ],
    exports: [
        AppRoutingModule,
        ArePickerComponent,
        BarRatingModule,
        MatSnackBarModule,
        MatSelectModule,
        MatStepperModule,
        MatCheckboxModule,
        MatInputModule,
        FormsModule,
        // ReactiveFormsModule,
        TooltipDirective,
        MatExpansionModule,
        MatChipsModule,
        MatSortModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatIconModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatListModule,
        ValidationFilterComponent,
        TranslateModule,
        NgxChartsModule,
        PwoFormsModule,
        PwoScdModule,
        HttpClientModule,
        ToggleComponent,
        NotificationComponent,
        UploadPeriodMessageComponent,
        CountrySelectComponent,
        FormatPipe,
        ProgressBarComponent,
        AreListComponent,
        PaginatorComponent,
        MonthPickerComponent,
        DisplayColumnsComponent,
        FilePickerComponent
    ],
    providers: [AwsServerlessApiService, LocalizedDatePipe, FormatPipe, ConsoleLoggerService, CurrencyPipe,
        { provide: LoggerService, useClass: ConsoleLoggerService }]
})
export class SharedModule { }
