import { Injectable } from '@angular/core';
import { AwsServerlessApiService } from '../shared/services/aws-serverless-api.service';
import { UserService } from '../shared/services/user.service';
import { VehicleContract } from '../shared/models/contract.model';
import { DateHelperService } from '../shared/services/date-helper.service';
import { User } from '../shared/models/user.model';
import {
  checkCountriesResponse,
  checkCountriesPayload,
  validateResponse,
  validatePayload,
  generateChangesResponse,
  generateChangesPayload,
  getChangesResponse,
  saveChangesResponse,
  declineChangesResponse,
  confirmChangesResponse,
  submitPayload,
  submitResponse,
  getChangesPayload,
  getAccountantChangesPayload,
  getAccountantChangesResponse,
  confirmChangesPayload,
  declineChangesPayload,
  saveChangesPayload,
  scmStatusResponse,
  scmStatusPayload,
  uploadDataPayload,
  getDataPayload,
  getFilterValuesPayload,
  getFilterValuesResponse,
  getChangesFilterValuesResponse,
  getChangesFilterValuesPayload
} from '../shared/models/api-model';
import { FilterRecord } from 'projects/pwo-filter-cloud/src/public_api';
import { uuid } from '../shared/uuid';
import { AreSelectItem } from '../admin/user-management/are-select/are-select.component';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  user: User;
  month: Date;

  cache: Partial<{ [key in keyof ApiService]: any }> = {};

  get aws() {
    return this.awsApi.fleet();
  }

  constructor(private awsApi: AwsServerlessApiService, private userService: UserService, private dateHelper: DateHelperService) {
    this.userService.user.subscribe((currentuser) => {
      if (!this.user && currentuser) {
        // fetch upload period as soon as authorized
        this.dateHelper.checkUploadPeriod().then(p => {
          this.month = moment.utc(p.monthISO).toDate();
        });
      }
      this.user = currentuser;
    });
    // this.setMonth(new Date());
  }

  setMonth(d: Date) {
    this.month = this.dateHelper.convertToMonth(d);
  }
  formatMonth(month = this.month) {
    return this.dateHelper.formatMonth(month);
  }

  async uploadData(data: any[]) {
    // Max payload is 6MB, so we have to split the data upload into multiple requests
    const CHUNK_SIZE = 5000; // 5000 items at a time
    const nonce = '' + Math.floor(Math.random() * 100000) + Date.now();
    for (let i = 0; i < data.length; i += CHUNK_SIZE) {
      const chunk = data.slice(i, i + CHUNK_SIZE);
      const payload: uploadDataPayload = {
        data: chunk,
        nonce,
        month: this.month.toISOString()
      };
      await this.aws.post('/uploadData', payload).toPromise(); // keep item order
    }
    return nonce;
  }

  getCountries(nonce: string): Promise<checkCountriesResponse> {
    const payload: checkCountriesPayload = {
      month: this.month.toISOString(),
      nonce
    };
    return this.aws.post('/checkCountries', payload).toPromise();
  }

  async validate(aresToUpload: string[]) {
    // const promises = [];
    // aresToUpload.forEach(ARE => {
    //   const payload: validatePayload = {
    //     month: this.month.toISOString(),
    //     ARE
    //   };
    //   promises.push(this.aws.post('/validate', payload).toPromise());
    // });
    const nonce = '' + Math.floor(Math.random() * 100000) + Date.now();
    for (let i = 0; i < aresToUpload.length; ++i) {
      const payload: validatePayload = { month: this.month.toISOString(), ARE: aresToUpload[i] };
      await this.aws.post('/validate', payload).toPromise();
    }

    return nonce;
  }

  async generateChangesForAREs(aresToUpload: string[]) {
    // const promises = [];
    // aresToUpload.forEach(are => {
    //   const payload: generateChangesPayload = { ARE: are, month: this.month.toISOString() };
    //   promises.push(this.aws.post('/generateChanges', payload).toPromise());
    // });
    const nonce = '' + Math.floor(Math.random() * 100000) + Date.now();
    for (let i = 0; i < aresToUpload.length; ++i) {
      console.log(`LENGTH: ${aresToUpload.length}`);
      console.log(i);
      const payload: generateChangesPayload = { ARE: aresToUpload[i], month : this.month.toISOString() };
      await this.aws.post('/generateChanges', payload).toPromise();
    }
    // return Promise.all(promises);
    return nonce;
  }

  getPaginatedChanges(areList: string[], page: number, limit: number, filters: FilterRecord[], sort: string, sortDir: 'asc' | 'desc' | '')
  : Promise<getChangesResponse> {
    const payload: getChangesPayload = {
      areList,
      month: this.month.toISOString(),
      page: page,
      limit: limit,
      filters, sort, sortDir
    };
    return this.aws.post('/getChanges', payload).toPromise().then(res => {
      res.items.forEach(el => {
        const old = {};
        if (el.diff) {
          el.diff.forEach(d => old[d.col] = d.val1);
        }
        el.oldValues = old;
      });
      return res;
    });
  }

  getChangesFV(areList: string[], self: FilterRecord, filters: FilterRecord[]): Promise<getChangesFilterValuesResponse> {
    const payload: getChangesFilterValuesPayload = {
      areList,
      month: this.month.toISOString(),
      self, filters
    };
    return this.aws.post('/getChangesFilterValues', payload).toPromise();
  }

  saveChanges(accepted: number[], declined: number[]/*, areList: string[], page, limit*/): Promise<saveChangesResponse> {
    const payload: saveChangesPayload = {
      accepted,
      declined,
      // areList,
      // month: this.month.toISOString(),
      // page: page,
      // limit: limit
    };
    console.log('PAYLOAD: ', payload);
    return this.aws.post('/saveChanges', payload).toPromise();
  }

  scmStatus(areList: string[]): Promise<scmStatusResponse> {
    const payload: scmStatusPayload = {
      month: this.month.toISOString(),
      areList
    };
    return this.aws.post('/scmStatus', payload).toPromise();

  }

  getAccountantChanges(ARE: string): Promise<getAccountantChangesResponse> {
    const payload: getAccountantChangesPayload = {
      month: this.month.toISOString(),
      ARE
    };
    return this.aws.post('/getAccountantChanges', payload).toPromise().then(result => {
      result.items.forEach(el => {
        const old = {};
        if (el.diff) {
          el.diff.forEach(d => old[d.col] = d.val1);
        }
        el.oldValues = old;
      });
      return result;
    });
  }

  submit(month: string, country: string, areList: string[]): Promise<submitResponse[]> {
    const promises = [];
    areList.forEach(ARE => {
      const payload: submitPayload = {
        callback_id: uuid(),
        country,
        ARE,
        month
      };
      this.aws.post('/submit', payload).toPromise();
      promises.push(this.waitFor(payload.callback_id, { maxTries: 30, interval: 5 }));
    });

    return Promise.all(promises);
  }

  clearProd(month, country) {
    return this.aws.post('/clearProd', {
      month,
      country
    }).toPromise();
  }

  clearARE(month, ARE) {
    return this.aws.post('/clearARE', {
      month,
      ARE
    }).toPromise();
  }

  declineChanges(ARE: string, message: string, month: string): Promise<declineChangesResponse> {
    const payload: declineChangesPayload = {
      ARE,
      month,
      message
    };
    return this.aws.post('/declineChanges', payload).toPromise();
  }

  confirmChanges(ARE: string, month: string): Promise<confirmChangesResponse> {
    const payload: confirmChangesPayload = {
      ARE,
      month
    };
    return this.aws.post('/confirmChanges', payload).toPromise();
  }

  search(term: string) {
    return this.aws.post('/search', {
      term
    }).toPromise();
  }

  getContract(vin_number): Promise<VehicleContract[]> {
    return this.aws.post('/getContract', {
      vin_number
    }).toPromise();
  }

  // getData(records: FilterRecord[], showChanges: boolean, page: number, limit: number,
  //   sort: string, sortDir: 'asc' | 'desc' | ''): Promise<VehicleContract[]> {
  //   console.log('showChanges', showChanges);
  //   const payload: getDataPayload = {
  //     showChanges,
  //     page, limit,
  //     filters: records,
  //     sort, sortDir
  //   };
  //   return this.aws.post('/getData', payload).toPromise();
  // }

  getData(itemsPerPage: number) {
    const payload = {
      itemsPerPage: itemsPerPage,
      user: this.user
    }
    return this.aws.post('/getData', payload).toPromise();
  }

  getFilterValues(filter: FilterRecord, records: FilterRecord[], showChanges: boolean): Promise<getFilterValuesResponse> {
    const payload: getFilterValuesPayload = { showChanges, filter, records };
    return this.aws.post('/getFilterValues', payload).toPromise(); // .then(res => res.map(el => el[filter.key]));
  }

  // async getExportData() {
  //   // get the summary
  //   // let res = await this.aws.post('/export', {
  //   //   month: this.month,
  //   //   type: 'summary'
  //   // }).toPromise();


  //   // get deleted items
  //   const deleted = await this._getAll('itemsDeleted');

  //   // get other items
  //   const other = await this._getAll('itemsChanged');

  //   return {
  //     items: [...deleted, ...other],
  //     month: this.month
  //   };
  // }

  // private async _getAll(type: string) {
  //   const limit = 5000;
  //   const result = [];

  //   let done = false;
  //   let page = 0;
  //   while (!done) {
  //     const items = await this.aws.post('/export', {
  //       month: this.month,
  //       page: page + '', // 0 is removed in the request -> would throw error, so send "0"
  //       limit,
  //       type
  //     }).toPromise();
  //     result.push(...items);
  //     page++;
  //     if (items.length < limit) { done = true; }
  //   }
  //   return result;
  // }

  dump(db, country) {
    return this.aws.post('/dump', {
      db,
      country,
      month: this.month
    }).toPromise();
  }

  async exportProd(type: 'data' | 'changes', filters: FilterRecord[]) {
    const limit = 5000;
    const result = [];

    let done = false, page = 1;
    while (!done) {
      const items = await this.aws.post('/exportProd', {
        type, filters, page, limit
      }).toPromise();
      result.push(...items);
      page++;
      if (items.length < limit) { done = true; }
    }
    return result;
  }

  async getAREList(): Promise<AreSelectItem[]> {
    if (this.cache.getAREList) { return this.cache.getAREList; }

    return this.aws.post('/areList', {}).toPromise().then(data => {
      this.cache.getAREList = data;
      return data;
    });
  }

  generateBDME(month: Date, areList: string) {
    const callback_id = uuid();
    this.aws.post('/generateBDME', { month, areList, callback_id }).toPromise();
    return this.waitFor(callback_id, { maxTries: 30, interval: 10 });
  }

  getBDMELinks(month: Date) {
    return this.aws.post('/getBDMELinks', { month }).toPromise();
  }

  storage(type: string, key: string) {
    return {
      getAll: () => {
        return this.aws.post('/storage', { type, key }).toPromise();
      },
      get: (range_key: string) => {
        return this.aws.post('/storage', { type, key, [key]: range_key }).toPromise().then(el => el[0]);
      },
      getByID: (id: number) => {
        return this.aws.post('/storage', { type, key, id }).toPromise().then(el => el[0]);
      }
    };
  }

  waitFor(event: string, options = { maxTries: 24, interval: 5 }) {
    return new Promise((resolve, reject) => {
      let tries = 0;
      const call = async () => {
        tries++;
        try {
          const item = await this.storage('queue', 'callback_id').get(event);
          if (item) {
            const data = JSON.parse(item.data);
            if (data && data.status === 'ok') {
              resolve(data.data);
            } else {
              reject(data.data);
            }
          } else {
            if (tries < options.maxTries) {
              setTimeout(() => call(), options.interval * 1000);
            } else {
              reject('Maximum number of tries reached');
            }
          }
        } catch (err) {
          reject(err);
        }
      };

      call();
    });
  }

}
