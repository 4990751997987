import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.authenticatedUser().then(user => {
      if (user && user.settings.isSystemAdmin) {
        return true;
      } else if (user && user.settings.isLocalAdmin && next.routeConfig.path === 'authorization') {
        return true;
      } else if (user && user.settings.isLocalAdmin && next.routeConfig.path === 'lars') {
        return true;
      } else if (user && user.settings.isLocalAdmin && next.routeConfig.path === 'sublease-config') {
        return true;
      } else {
        this.router.navigateByUrl('/');
        return false;
      }
    });

  }

}
