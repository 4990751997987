import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInjector implements HttpInterceptor {
    constructor(private oidcSecurityService: OidcSecurityService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.oidcSecurityService.getIdToken().pipe(
            switchMap((token) => {
                if (token) {
                  const apiUrl = environment.webapi;
                    if (request.url.includes(this.removeProtocol(apiUrl))) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                    }
                }
                return next.handle(request);
            })
        );
    }

    private removeProtocol = (str: string): string => str.replace(/^https?:\/\//, '');
}