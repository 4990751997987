import { Injectable, Injector } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, } from 'rxjs';
import { AuthUser } from '../models/auth-user.model';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthenticated$: Observable<boolean>;
  public authenticatedUser: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(null);
  public authenticatedUser$: Observable<AuthUser> = this.authenticatedUser.asObservable();

  constructor(private oidcSecurityService: OidcSecurityService) {
    this.isAuthenticated$ = this.oidcSecurityService.checkAuth().pipe(
      tap((auth) => {
        this.authenticatedUser.next(auth.userData as AuthUser);
      }),
      map((auth) => auth.isAuthenticated)
    );
  }

  public login(): void {
    const url = window.location.pathname + window.location.search;
    window.localStorage.setItem('redirectUrl', url);
    this.oidcSecurityService.authorize();
  }

  public logout(): void {
    this.oidcSecurityService.logoff();
  }
}